import React, { Component } from 'react';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Label,
  Input,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Alert,
  CustomInput
} from 'reactstrap';
import Select from 'react-select';

// Importing styles
import styles from './SurveyTool.module.scss';
import "../../assets/css/m_w.css";
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faLock, faUnlock, faPlus, faCaretDown, faCheck, faTimes, faArrowLeft, faArrowRight, faQuestionCircle, faSpinner, faSearch, faExpand, faExternalLinkAlt, faFilter, faExclamationCircle, faClipboard, faCog } from '@fortawesome/free-solid-svg-icons';

// Components
import AudioInput from './AudioInput.jsx';

// Crypto Functionality (encrypt/decrypt) and variables
import { ARWEAVE_ACTIVE } from '../../variables/CONTRACT_ADDRESSES';
import contractScripts from '../Buttons/contractScripts.js';
import { encrypt } from '@metamask/eth-sig-util';
import { ethers, utils } from 'ethers';
import sha256 from 'crypto-js/sha256';
import Slider from 'react-rangeslider';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';

class SurveyTool extends Component {
  constructor(props) {
    super(props);
    // Initialize cache from localStorage or set default structure
    let savedCache;
    const savedCacheStr = localStorage.getItem('surveyCache');
    if (savedCacheStr) {
      try {
        savedCache = JSON.parse(savedCacheStr);
      } catch (e) {
        console.error('Error parsing saved cache from localStorage:', e);
        savedCache = {
          surveyIDs: [],
          questionIDs: [],
          questionResponses: {},
          arweaveContent: {},
        };
      }
    } else {
      savedCache = {
        surveyIDs: [],
        questionIDs: [],
        questionResponses: {},
        arweaveContent: {},
      };
    }

    this.state = {
      cache: savedCache,
      latestBlockNumber: 0,
      events: [],
    };
  }


  updateCache = (updater, callback) => {
    if (typeof updater !== 'function') {
      console.error('updateCache expects a function as its argument, but received:', updater);
      return;
    }
    this.setState((prevState) => {
      const newCache = updater(prevState.cache);
      // Save updated cache to localStorage
      localStorage.setItem('surveyCache', JSON.stringify(newCache));
      return { cache: newCache };
    }, callback);
  };


  componentDidMount() {
    this.initializeEventListener();
    this.loadInitialData();
    this.fetchAllQuestionIDs(); // New function to fetch question IDs
  }

  fetchAllQuestionIDs = async () => {
    try {
      const questionIDs = await contractScripts.fetchAllQuestionIDs(this.props.provider);
      if (!questionIDs || questionIDs.length === 0) {
        console.warn('No question IDs fetched.');
        return;
      }
      console.log('Fetched question IDs:', questionIDs);

      this.updateCache((prevCache) => ({
        ...prevCache,
        questionIDs,
      }));

      // Fetch question details and update cache
      for (let questionId of questionIDs) {
        let questionData = this.state.cache.arweaveContent[questionId];
        if (!questionData) {
          questionData = await contractScripts.getQuestionData(this.props.provider, questionId);
          if (questionData) {
            this.updateCache((prevCache) => ({
              ...prevCache,
              arweaveContent: {
                ...prevCache.arweaveContent,
                [questionId]: questionData,
              },
            }));
          } else {
            console.warn(`Question data not found for ID: ${questionId}`);
          }
        }
      }

      // After updating cache, fetch responses for all questions
      this.fetchAllQuestionResponses();
    } catch (error) {
      console.error('Error fetching all question IDs:', error);
    }
  };


  // Function to fetch responses for all questions
  fetchAllQuestionResponses = async () => {
    const { cache } = this.state;
    const { questionIDs } = cache;

    for (let questionId of questionIDs) {
      const responses = await contractScripts.getResponsesByQuestionID(this.props.provider, questionId);
      this.updateCache((prevCache) => ({
        ...prevCache,
        questionResponses: {
          ...prevCache.questionResponses,
          [questionId]: responses,
        },
      }));
    }
  };


  componentDidUpdate(prevProps, prevState) {
    // Check if cache has changed in a meaningful way
    const cacheChanged =
      this.state.cache.surveyIDs.length !== prevState.cache.surveyIDs.length ||
      Object.keys(this.state.cache.arweaveContent).length !== Object.keys(prevState.cache.arweaveContent).length;

    if (cacheChanged) {
      this.fetchSurveys();
    }
  }

  // Initialize event listeners for surveys (if applicable)
  initializeEventListener = () => {
    // Example: Listen for new surveys added via smart contract events
    contractScripts.listenForSurveyEvents(this.props.provider, this.handleNewEvent);
  };

  // Handle new events and update cache accordingly
  handleNewEvent = (event) => {
    console.log('New event detected:', event);
    this.updateCacheFromEvent(event);
  };

  // Update cache based on the received event
  updateCacheFromEvent = async (event) => {
    // Implement logic based on the event type
    // Example:
    /*
    if (event.event === 'SurveyAdded') {
      const surveyID = event.args.surveyId;
      const surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyID);
      // Fetch question data
      surveyData.questions = [];
      for (let questionID of surveyData.questionIDs) {
        let questionData = await contractScripts.getQuestionData(this.props.provider, questionID);
        questionData.id = questionID;
        surveyData.questions.push(questionData);
      }
      // Update cache
      this.updateCache(prevCache => ({
        ...prevCache,
        surveyIDs: [...prevCache.surveyIDs, surveyID],
        arweaveContent: {
          ...prevCache.arweaveContent,
          [surveyID]: surveyData
        }
      }));
    }
    */
    // Implement other event types as needed
  };

  // Fetch survey data by survey ID
  getSurveyData = async (surveyID) => {
    console.log('Getting survey data for ID:', surveyID);
    let surveyData = this.state.cache.arweaveContent[surveyID];

    if (!surveyData) {
      surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyID);
      if (!surveyData) {
        console.warn(`No survey data found for ID ${surveyID}`);
        return null;
      }
      console.log(`Fetched survey data for ID ${surveyID}:`, surveyData);

      // Fetch question data and include in surveyData
      surveyData.questions = [];
      for (let questionID of surveyData.questionIDs) {
        let questionData = await contractScripts.getQuestionData(this.props.provider, questionID);
        questionData.id = questionID; // Ensure each question has its ID
        surveyData.questions.push(questionData);
      }

      // Update cache
      this.updateCache(prevCache => ({
        ...prevCache,
        arweaveContent: {
          ...prevCache.arweaveContent,
          [surveyID]: surveyData
        }
      }));
    }

    return surveyData;
  };

  // Load initial data: fetch survey IDs and their data
  loadInitialData = async () => {
    console.log('Loading initial data');
    try {
      // Fetch survey IDs submitted by the user
      const surveyIDs = await contractScripts.fetchUserSubmittedSurveyIDs(this.props.provider);
      console.log('Fetched survey IDs:', surveyIDs);

      // Update cache with survey IDs
      this.updateCache(prevCache => ({
        ...prevCache,
        surveyIDs,
      }));

      // Fetch survey data and related questions
      for (let surveyID of surveyIDs) {
        const surveyData = await this.getSurveyData(surveyID);
        console.log(`Fetched survey data for ID ${surveyID}:`, surveyData);
      }

      // Fetch latest block number (if needed)
      const latestBlockNumber = await contractScripts.getLatestBlockNumber(this.props.provider);
      this.setState({ latestBlockNumber });
      console.log('Latest block number:', latestBlockNumber);
    } catch (error) {
      console.error("Error loading initial data:", error);
    }
  };

  // fetchAllQuestions = async () => {
  //   try {
  //     const allQuestions = await contractScripts.fetchAllQuestionResponses(this.props.provider);
  //     // Process and store the questions as needed
  //     this.setState({ questionPool: allQuestions });
  //   } catch (error) {
  //     console.error("Error fetching all questions:", error);
  //   }
  // };


  // Fetch surveys based on cache
  fetchSurveys = async () => {
    console.log('Fetching surveys');
    try {
      const surveyIDs = this.state.cache.surveyIDs;
      console.log('Using cached survey IDs:', surveyIDs);
      const userSubmittedSurveys = [];
      const surveySet = new Set();

      for (let surveyID of surveyIDs) {
        if (!surveyID) continue; // Skip undefined IDs
        const surveyData = await this.getSurveyData(surveyID);
        if (!surveyData || !surveyData.title || !surveyData.questionIDs) {
          console.warn(`Survey data for ID ${surveyID} is invalid.`);
          continue;
        }
        surveyData.id = surveyID;

        if (!surveySet.has(surveyID)) {
          surveySet.add(surveyID);
          userSubmittedSurveys.push(surveyData);
          console.log(`Added survey to list: ID=${surveyID}, Data=`, surveyData);
        }
      }

      // Optionally, update state with fetched surveys if needed
      // this.setState({ surveys: userSubmittedSurveys, loading: false });
      console.log('Fetched surveys:', userSubmittedSurveys);
    } catch (error) {
      console.error("Error fetching surveys:", error);
    }
  };

  clearCache = () => {
    console.log('Clearing cache');
    localStorage.removeItem('surveyCache');
    this.updateCache(() => ({
      surveyIDs: [],
      questionIDs: {},
      arweaveContent: {},
    }));
    console.log('Cache cleared. Reloading initial data...');
    this.loadInitialData();
  };

  render() {
    return (
      <div id={styles.surveySelectorRow}>
        <SurveySelector
          surveyID={this.props.surveyID}
          displayAnswerMode={this.props.displayAnswerMode}
          viewAddress={this.props.viewAddress}
          account={this.props.account}
          provider={this.props.provider}
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          loginComplete={this.props.loginComplete}
          cache={this.state.cache}
          updateCache={this.updateCache}
        />
        {/* <Button onClick={this.clearCache} style={{ marginLeft: '10px' }}>
          Clear Cache
        </Button> */}
      </div>
    );
  }
}

class SurveySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
      selectedSurveyIndex: null,
      pubKey: '',
      createSurveyMode: false,
      demoMode: false,
      showResults: false,
      loading: true,
      filterModalOpen: false, 
    };
  }

  async componentDidMount() {
    await this.fetchSurveys();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.surveyID !== prevProps.surveyID) {
      await this.updateSelectedSurvey();
    }
    // Only fetch surveys if cache changes significantly
    const cacheChanged =
      this.props.cache.surveyIDs.length !== prevProps.cache.surveyIDs.length ||
      Object.keys(this.props.cache.arweaveContent).length !== Object.keys(prevProps.cache.arweaveContent).length;

    if (cacheChanged) {
      await this.fetchSurveys();
    }
  }

  updatePubKey = (newPubkey) => {
    this.setState({ pubKey: newPubkey });
  };

  fetchSurveys = async () => {
    console.log('Fetching surveys');
    if (!this.state.demoMode) {
      try {
        const surveyIDs = this.props.cache.surveyIDs;
        console.log('Using cached survey IDs:', surveyIDs);
        const userSubmittedSurveys = [];
        const surveySet = new Set();

        for (let surveyID of surveyIDs) {
          if (!surveyID) continue; // Skip undefined IDs
          const surveyData = await this.getSurveyData(surveyID);
          if (!surveyData || !surveyData.title || !surveyData.questionIDs) {
            console.warn(`Survey data for ID ${surveyID} is invalid.`);
            continue;
          }
          surveyData.id = surveyID;

          if (!surveySet.has(surveyID)) {
            surveySet.add(surveyID);
            userSubmittedSurveys.push(surveyData);
            console.log(`Added survey to list: ID=${surveyID}, Data=`, surveyData);
          }
        }

        this.setState({ surveys: userSubmittedSurveys, loading: false }, this.updateSelectedSurvey);
        console.log('Fetched surveys:', userSubmittedSurveys);
      } catch (error) {
        console.error("Error fetching surveys:", error);
        this.setState({ loading: false });
      }
    } else {
      this.setState({ surveys: [], loading: false }, this.updateSelectedSurvey);
    }
  }

  getSurveyData = async (surveyID) => {
    console.log('Getting survey data for ID:', surveyID);
    let surveyData = this.props.cache.arweaveContent[surveyID];

    if (!surveyData) {
      surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyID);
      if (!surveyData) {
        console.warn(`No survey data found for ID ${surveyID}`);
        return null;
      }
      // Fetch question data and include in surveyData
      surveyData.questions = [];
      for (let questionID of surveyData.questionIDs) {
        let questionData = await contractScripts.getQuestionData(this.props.provider, questionID);
        questionData.id = questionID; // Ensure each question has its ID
        surveyData.questions.push(questionData);
      }

      // Update cache
      this.props.updateCache(prevCache => ({
        ...prevCache,
        arweaveContent: {
          ...prevCache.arweaveContent,
          [surveyID]: surveyData
        }
      }));
    }

    return surveyData;
  };

  updateSelectedSurvey = () => {
    const { surveyID } = this.props;
    const { surveys } = this.state;

    const selectedIndex = surveys.findIndex(survey => survey.id === surveyID);

    if (selectedIndex !== -1) {
      this.setState({ selectedSurveyIndex: selectedIndex });
      console.log(`Selected survey index updated to ${selectedIndex}`);
    } else if (surveys.length > 0) {
      this.setState({ selectedSurveyIndex: 0 }, () => {
        console.log('No matching survey ID found. Defaulting to first survey.');
        this.updateURL(surveys[0].id);
      });
    }
  }

  selectSurvey = (index) => {
    this.setState({ selectedSurveyIndex: index }, () => {
      const newSurveyId = this.state.surveys[index].id;
      console.log(`Survey selected: Index=${index}, ID=${newSurveyId}`);
      this.updateURL(newSurveyId);
    });
  };

  updateURL = (surveyId) => {
    if (this.props.displayAnswerMode && this.props.viewAddress) {
      window.history.pushState({}, '', `/survey/${surveyId}/${this.props.viewAddress}`);
    } else {
      window.history.pushState({}, '', `/survey/${surveyId}`);
    }
    if (this.props.onSurveyChange) {
      this.props.onSurveyChange(surveyId);
    }
    console.log(`URL updated to /survey/${surveyId}${this.props.viewAddress ? `/${this.props.viewAddress}` : ''}`);
  }

  updatePubKey = (newPubkey) => {
    this.setState({ pubKey: newPubkey });
  };

  toggleFilterModal = () => {
    this.setState(prevState => ({ filterModalOpen: !prevState.filterModalOpen }));
  };

  toggleCreateMode = () => {
    this.setState({ createSurveyMode: !this.state.createSurveyMode }, () => {
      console.log(`Create survey mode toggled to ${this.state.createSurveyMode}`);
    });
  };

  toggleShowResults = () => {
    this.setState({ showResults: !this.state.showResults }, () => {
      console.log(`Show results toggled to ${this.state.showResults}`);
    });
  };

  toggleShowQuestions = () => {
    this.setState(prevState => ({ showQuestions: !prevState.showQuestions }));
  };

  copySurveyIdToClipboard = () => {
    const { surveyHash } = this.state;
    navigator.clipboard.writeText(surveyHash)
      .then(() => {
        console.log(`Successfully copied Survey ID: ${surveyHash}`);
      })
      .catch((err) => {
        console.error('Failed to copy Survey ID: ', err);
      });
  };

  render() {
    const { surveys, selectedSurveyIndex, createSurveyMode, showResults, loading, showQuestions, filterModalOpen } = this.state;
    const selectedSurvey = selectedSurveyIndex !== null && surveys[selectedSurveyIndex] ? surveys[selectedSurveyIndex] : null;
    const { surveyAddedSuccessfully, surveyHash, title, questions, isSubmitting, progress, showJson, isStandaloneQuestion, submissionError } = this.state;
    const surveyID = isStandaloneQuestion ? '' : surveyHash;

    const dropdownTitle = showQuestions
      ? 'Questions'
      : selectedSurvey
        ? selectedSurvey.title
        : 'Select survey';

    const createSurveyComponent = (
      <CreateSurvey
        account={this.props.account}
        loginComplete={this.props.loginComplete}
        provider={this.props.provider}
        toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
        expanded={createSurveyMode}
        surveys={surveys}
        surveyIndex={selectedSurveyIndex}
        cache={this.props.cache}
        updateCache={this.updateCache}
      />
    );

    return (
      <div>
        <div id={styles.surveysRow}>
          <UncontrolledDropdown>
            <DropdownToggle id={styles.dropdownToggle}>
              {loading ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} spin /> {'Loading Surveys'}
                </>
              ) : (
                <>
                  {dropdownTitle}
                  <FontAwesomeIcon icon={faCaretDown} id={styles.dropdownToggleCaret} />
                </>
              )}
            </DropdownToggle>
            {!showQuestions && (
              <DropdownMenu id={styles.dropdownMenu}>
                {surveys.map((survey, index) => (
                  <DropdownItem className={styles.dropdownItem} key={index} onClick={() => this.selectSurvey(index)}>
                    {survey.title}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            )}
            {/* Display the surveyID with a clipboard icon */}
            {selectedSurvey && !showQuestions && (
              <div className={styles.surveyIdContainer}>
                <span className={styles.surveyIdText}>Survey ID: {proposalScripts.getShortenedSurveyID(selectedSurvey.id, false)}</span>
                <Button className={styles.copySurveyIdButton} onClick={this.copySurveyIdToClipboard}>
                  <FontAwesomeIcon icon={faClipboard} />
                </Button>
              </div>
            )}
           
           {/* Add gear icon beside the dropdown */}
          {showQuestions && (
            <Button id={styles.filterButton} onClick={this.toggleFilterModal}>
              <FontAwesomeIcon icon={faCog} />
            </Button>
          )}

          </UncontrolledDropdown>

           {/* Toggle between 'See Questions' and 'See Surveys' */}
           <FormGroup check id={styles.seeQuestionsCheckbox} className={styles.seeQuestionsCheckbox}>
            <Label check>
              <Input type="checkbox" onChange={this.toggleShowQuestions} />{' '}
              {showQuestions ? 'See Surveys' : 'See Questions'}
            </Label>
          </FormGroup>

          {selectedSurvey && !showQuestions && (
            <Button onClick={this.toggleShowResults} id={styles.showResultsButton}>
              Results
            </Button>
          )}

          <button id={styles.createSurveyButton} onClick={this.toggleCreateMode}>
            {createSurveyMode ? 'Exit Survey Creation' : 'Add Question / Survey'}
          </button>
        </div>

        {createSurveyMode ? createSurveyComponent : null}

        {selectedSurvey && !showQuestions ? (
          <SurveyQuestions
            displayAnswerMode={this.props.displayAnswerMode}
            viewAddress={this.props.viewAddress}
            account={this.props.account}
            provider={this.props.provider}
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            surveys={surveys}
            surveyIndex={selectedSurveyIndex}
            loginComplete={this.props.loginComplete}
            pubKey={this.state.pubKey}
            updatePubKey={(newPubkey) => this.updatePubKey(newPubkey)}
            surveyID={selectedSurvey.id}
            cache={this.props.cache}
            updateCache={this.props.updateCache}
          />
        ) : null}

        {this.state.showResults && selectedSurvey && !showQuestions && (
          <SurveyResults
            provider={this.props.provider}
            surveyId={selectedSurvey.id}
            onClose={this.toggleShowResults}
            cache={this.props.cache}
            updateCache={this.props.updateCache}
          />
        )}

        {/* Render the QuestionsDashboard if showQuestions is true */}
        {showQuestions && (
          <QuestionsDashboard
            account={this.props.account}
            provider={this.props.provider}
            toggleLoginModal={this.props.toggleLoginModal}
            filterModalOpen={filterModalOpen}
            toggleFilterModal={this.toggleFilterModal}
            loginComplete={this.props.loginComplete}
            cache={this.props.cache}
            updateCache={this.props.updateCache}
          />
        )}
      </div>
    );
  }

}

class QuestionsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      selectedQuestions: [],
      selectedTypes: [],
      sortByImportance: false,
    };
  }

  componentDidMount() {
    this.loadQuestions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.cache.questionIDs !== this.props.cache.questionIDs ||
      prevProps.cache.arweaveContent !== this.props.cache.arweaveContent ||
      prevProps.cache.questionResponses !== this.props.cache.questionResponses ||
      prevState.selectedTypes !== this.state.selectedTypes ||
      prevState.sortByImportance !== this.state.sortByImportance
    ) {
      this.loadQuestions();
    }
  }

  loadQuestions = () => {
    const { cache } = this.props;
    let questions = cache.questionIDs.map(questionId => {
      return {
        id: questionId,
        ...cache.arweaveContent[questionId],
      };
    });

    // Filter questions by selected types
    if (this.state.selectedTypes.length > 0) {
      questions = questions.filter(q => this.state.selectedTypes.includes(q.type));
    }

    // Calculate cumulative importance
    const { questionResponses } = cache;
    questions = questions.map(question => {
      const responses = questionResponses[question.id] || [];
      const totalImportance = responses.reduce((sum, response) => {
        return sum + (response.importance || 0);
      }, 0);
      return {
        ...question,
        totalImportance,
      };
    });

    // Sort questions by cumulative importance
    if (this.state.sortByImportance) {
      questions.sort((a, b) => b.totalImportance - a.totalImportance);
    }

    this.setState({ questions });
  };

  toggleFilterModal = () => {
    this.setState(prevState => ({ filterModalOpen: !prevState.filterModalOpen }));
  };

  handleTypeSelection = (type) => {
    this.setState(prevState => {
      let selectedTypes = [...prevState.selectedTypes];
      if (selectedTypes.includes(type)) {
        selectedTypes = selectedTypes.filter(t => t !== type);
      } else {
        selectedTypes.push(type);
      }
      return { selectedTypes };
    });
  };

  handleSortByImportance = () => {
    this.setState(prevState => ({ sortByImportance: !prevState.sortByImportance }));
  };

  render() {
    const { questions, selectedTypes, sortByImportance } = this.state;
    const { filterModalOpen, toggleFilterModal } = this.props;
    const questionTypes = ['freeform', 'multichoice', 'rating', 'binary'];

    return (
      <div className={styles.questionsDashboard}>
        <div className={styles.questionsHeader}>
          {/* <Button className={styles.filterButton} onClick={this.toggleFilterModal}>
            Filter <FontAwesomeIcon icon={faCog} />
          </Button> */}
        </div>

        {/* Filter Modal */}
        <Modal isOpen={filterModalOpen}>
          <ModalHeader>Filter Questions</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Question Types:</Label>
              {questionTypes.map((type, index) => (
                <FormGroup check key={index}>
                  <Label check>
                    <Input
                      type="checkbox"
                      value={type}
                      checked={selectedTypes.includes(type)}
                      onChange={() => this.handleTypeSelection(type)}
                    />
                    {type}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={sortByImportance}
                  onChange={this.handleSortByImportance}
                />
                Sort by Cumulative Importance
              </Label>
            </FormGroup>
            </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggleFilterModal}>Close</Button>
          </ModalFooter>
        </Modal>

        {/* Render SurveyQuestions component */}
        <SurveyQuestions
          account={this.props.account}
          provider={this.props.provider}
          toggleLoginModal={this.props.toggleLoginModal}
          // toggleFilterModal={() => this.toggleFilterModal()}
          loginComplete={this.props.loginComplete}
          cache={this.props.cache}
          updateCache={this.props.updateCache}
          questionPool={questions}
          isStandalone={true}
        />

      </div>
    );
  }
}

class SurveyQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      surveysResponseState: this.initializeSurveyResponseState(),
      displayAnswerMode: this.props.displayAnswerMode,
      viewAddressAnswers: '',
      noResponse: false,
      userHasResponse: false,
      userResponseEncrypted: false,
      startFresh: false,
      userAnswers: null,
      isDecrypting: false,
      jsonPreview: '',
      isEditing: false,
      isSubmitting: false,
      submitProgress: 0,
      submissionComplete: false,
      responseUrl: '',
      submissionError: '',
      currentStep: 0,
      questionPool: this.props.isStandalone ? this.props.questionPool || [] : [],
      showJson: false, 
    };
  }


  initializeSurveyResponseState = () => {
    if (this.props.isStandalone) {
      let initialAnswers = {};
      let initialAdditionalThoughts = {};

      if (this.props.questionPool && Array.isArray(this.props.questionPool)) {
        this.props.questionPool.forEach((question) => {
          initialAnswers[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
          initialAdditionalThoughts[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
        });
      }

      return [{
        answers: initialAnswers,
        importance: {},
        additionalComments: initialAdditionalThoughts
      }];
    } else {
      // Original logic
      return this.props.surveys.map(survey => {
        let initialAnswers = {};
        let initialAdditionalThoughts = {};

        if (survey.questionIDs && Array.isArray(survey.questionIDs)) {
          survey.questionIDs.forEach((questionId) => {
            initialAnswers[questionId] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
            initialAdditionalThoughts[questionId] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
          });
        }

        return {
          answers: initialAnswers,
          importance: {},
          additionalComments: initialAdditionalThoughts
        };
      });
    }
  };

  async componentDidMount() {
    if (!this.props.isStandalone) {
      await this.fetchSurveyResponse();
      await this.fetchQuestionPool();
      this.checkAndHandleStartFresh();
    } else {
      this.setState({ jsonPreview: this.prepareJsonAndHash(0) });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.isStandalone) {
      if (prevProps.questionPool !== this.props.questionPool) {
        this.setState({
          questionPool: this.props.questionPool || [],
          surveysResponseState: this.initializeSurveyResponseState()
        }, () => {
          this.setState({ jsonPreview: this.prepareJsonAndHash(0) });
        });
      }
    } else {
      if (this.props.surveyID !== prevProps.surveyID) {
        this.setState({
          surveysResponseState: this.initializeSurveyResponseState()
        }, async () => {
          await this.fetchSurveyResponse();
          await this.fetchQuestionPool();
          this.checkAndHandleStartFresh();
        });
      }
      if (this.props.account !== prevProps.account || this.props.viewAddress !== prevProps.viewAddress) {
        await this.fetchSurveyResponse();
      }
    }
  }

  checkAndHandleStartFresh = () => {
    if (!this.props.viewAddress && !this.state.userHasResponse) {
      this.handleStartFresh();
    }
  }

  async fetchQuestionPool() {
    console.log('Fetching question pool');
    if (this.props.isStandalone) {
      this.setState({ questionPool: this.props.questionPool || [] });
    } else {
      const { surveyID, cache } = this.props;
      const survey = cache.arweaveContent[surveyID];

      if (!survey) {
        console.error('Survey not found in cache');
        return;
      }

      const questionPool = await Promise.all(survey.questionIDs.map(async (questionId) => {
        let questionData = cache.arweaveContent[questionId];

        if (!questionData) {
          questionData = await contractScripts.getQuestionData(this.props.provider, questionId);
          // Update cache
          this.props.updateCache(prevCache => ({
            ...prevCache,
            arweaveContent: {
              ...prevCache.arweaveContent,
              [questionId]: questionData
            }
          }));
        }

        return { ...questionData, id: questionId };
      }));

      this.setState({ questionPool });
    }
  }

  fetchSurveyResponse = async () => {
    if (this.props.displayAnswerMode && this.props.viewAddress) {
      try {
        const viewAnswers = await this.getSurveyResponse(this.props.viewAddress, this.props.surveyID);
        if (viewAnswers) {
          this.setState({ viewAddressAnswers: JSON.stringify(viewAnswers), noResponse: false });
        } else {
          this.setState({ viewAddressAnswers: '', noResponse: true });
        }
      } catch (error) {
        console.error("Error fetching survey response:", error);
        this.setState({ viewAddressAnswers: '', noResponse: true });
      }
    } else {
      this.setState({ viewAddressAnswers: '', noResponse: false });
    }

    if (this.props.account) {
      try {
        const userAnswers = await this.getSurveyResponse(this.props.account, this.props.surveyID);
        if (userAnswers) {
          const hasEncryptedPortion = userAnswers.responses.some(r => r.encryptedPortion && r.encryptedPortion !== '');
          this.setState({
            userHasResponse: true,
            userResponseEncrypted: hasEncryptedPortion,
            startFresh: false,
            userAnswers: userAnswers,
          });
          this.prefillSurveyResponses(userAnswers);
        } else {
          this.setState({ userHasResponse: false, userResponseEncrypted: false, userAnswers: null });
        }
      } catch (error) {
        console.error("Error fetching user's survey response:", error);
        this.setState({ userHasResponse: false, userResponseEncrypted: false, userAnswers: null });
      }
    }
  };

  // Updated prefillSurveyResponses to include encryptedPortion
  prefillSurveyResponses = (userAnswers) => {
    const newSurveysResponseState = [...this.state.surveysResponseState];
    const currentSurvey = newSurveysResponseState[this.props.surveyIndex];

    userAnswers.responses.forEach((response) => {
      const questionId = response.questionID;
      currentSurvey.answers[questionId] = {
        value: this.parseAnswerValue(response.answer.value),
        encrypted: response.answer.encrypted,
        hash: response.answer.hash || '',
        encryptedPortion: response.answer.encryptedPortion || ''
      };
      currentSurvey.importance[questionId] = response.importance;
      currentSurvey.additionalComments[questionId] = {
        value: this.parseAnswerValue(response.additional.value),
        encrypted: response.additional.encrypted,
        hash: response.additional.hash || '',
        encryptedPortion: response.additional.encryptedPortion || ''
      };
    });

    this.setState({
      surveysResponseState: newSurveysResponseState,
      jsonPreview: this.prepareJsonAndHash(this.props.surveyIndex)
    });
  };

  parseAnswerValue = (value) => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  };

  handleStartFresh = () => {
    const freshState = this.initializeSurveyResponseState();
    this.setState({
      startFresh: true,
      surveysResponseState: freshState,
      displayAnswerMode: false,
      userHasResponse: false,
      userResponseEncrypted: false,
      isEditing: false,
    }, () => {
      this.updateJsonPreview();
    });
  };

  handleDecryptEdit = async () => {
    this.setState({ isDecrypting: true });
    try {
      await this.decryptMultipleAnswers();
      this.setState({
        startFresh: false,
        isDecrypting: false,
        displayAnswerMode: false,
        userResponseEncrypted: false,
        isEditing: true,
      }, () => {
        this.updateJsonPreview();
      });
    } catch (error) {
      console.error("Error decrypting answers:", error);
      this.setState({ isDecrypting: false });
    }
  };

  handleAnswer = (surveyIndex, questionId, answer) => {
    const newSurveysResponseState = [...this.state.surveysResponseState];
    let answerStr;

    if (Array.isArray(answer) || typeof answer === "number") {
      answerStr = JSON.stringify(answer);
    } else {
      answerStr = answer;
    }

    newSurveysResponseState[surveyIndex].answers[questionId] = {
      ...newSurveysResponseState[surveyIndex].answers[questionId],
      value: answer
    };

    const question = this.state.questionPool.find(q => q.id === questionId);
    const isBinaryQuestion = question && question.type === 'binary';
    if (!(Array.isArray(answer)) && typeof answer !== "number" && !isBinaryQuestion) {
      const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(answerStr));
      newSurveysResponseState[surveyIndex].answers[questionId].hash = newAnswerHash;
    }

    this.setState({ surveysResponseState: newSurveysResponseState }, () => {
      this.updateJsonPreview();
    });
  };

  toggleShowJson = () => {
    this.setState(prevState => ({ showJson: !prevState.showJson }));
  };

  toggleDisplayAnswerMode = () => {
    this.setState(prevState => ({
      displayAnswerMode: !prevState.displayAnswerMode,
    }), () => {
      if (!this.state.displayAnswerMode) {
        this.setState({ viewAddressAnswers: '' });
      } else {
        this.fetchSurveyResponse();
      }
      this.updateJsonPreview();
    });
  };

  handleAdditional = (surveyIndex, questionId, additionalComments) => {
    const newSurveysResponseState = [...this.state.surveysResponseState];
    newSurveysResponseState[surveyIndex].additionalComments[questionId] = {
      ...newSurveysResponseState[surveyIndex].additionalComments[questionId],
      value: additionalComments
    };

    const newAnswer = newSurveysResponseState[surveyIndex].additionalComments[questionId].value;
    const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(newAnswer));
    newSurveysResponseState[surveyIndex].additionalComments[questionId].hash = newAnswerHash;
    this.setState({ surveysResponseState: newSurveysResponseState }, () => {
      this.updateJsonPreview();
    });
  };

  handleImportance = (surveyIndex, questionId, importance) => {
    const newSurveysResponseState = [...this.state.surveysResponseState];
    newSurveysResponseState[surveyIndex].importance[questionId] = importance;
    this.setState({ surveysResponseState: newSurveysResponseState }, () => {
      this.updateJsonPreview();
    });
  };

  handleUpdateResponse = async () => {
    if (this.state.isEditing) {
      await this.encryptAndUpload();
      this.setState({ isEditing: false, userHasResponse: true, userResponseEncrypted: true });
    } else {
      this.handleDecryptEdit();
    }
  };

  toggleAnswerEncryption = (surveyIndex, questionId, newEncryptedState) => {
    const newSurveysResponseState = [...this.state.surveysResponseState];
    if (newSurveysResponseState[surveyIndex].answers[questionId]) {
      newSurveysResponseState[surveyIndex].answers[questionId].encrypted = newEncryptedState;
      this.setState({ surveysResponseState: newSurveysResponseState }, () => {
        this.updateJsonPreview();
      });
    }
  };

  toggleAdditionalCommentsEncryption = (surveyIndex, questionId, newEncryptedState) => {
    const newSurveysResponseState = [...this.state.surveysResponseState];
    if (newSurveysResponseState[surveyIndex].additionalComments[questionId]) {
      newSurveysResponseState[surveyIndex].additionalComments[questionId].encrypted = newEncryptedState;
      this.setState({ surveysResponseState: newSurveysResponseState }, () => {
        this.updateJsonPreview();
      });
    }
  };

  getSurveyResponse = async (responderAddress, surveyID) => {
    console.log("getSurveyResponse() - invoked with surveyID:", surveyID);

    const surveyAnswers = await contractScripts.getSurveyResponse(this.props.provider, responderAddress, surveyID);

    console.log("surveyAnswers: ", surveyAnswers);

    return surveyAnswers;
  };

  prepareJsonAndHash = (surveyIndex) => {
    const surveyHash = this.props.isStandalone ? undefined : this.props.surveyID;
    const surveyResponseState = this.state.surveysResponseState[surveyIndex];
    const surveyQuestions = this.state.questionPool;
  
    if (!surveyQuestions || !surveyResponseState) {
      return null;
    }
  
    const response = {
      ...(surveyHash !== undefined && { surveyID: surveyHash }),
      address: this.props.account,
      timeStamp: Date.now(),
      responses: surveyQuestions.map((question) => {
        const questionId = question.id;
        const answer = surveyResponseState.answers[questionId] || { value: '', encrypted: false, hash: '' };
        const additional = surveyResponseState.additionalComments[questionId] || { value: '', encrypted: false, hash: '' };
        const importance = surveyResponseState.importance[questionId];
  
        let responseItem = {
          questionID: questionId,
          prompt: question.prompt,
          type: question.type,
          importance: importance !== undefined ? importance : null,
          answer: {
            value: answer.value,
            encrypted: answer.encrypted,
            hash: answer.hash || '',
            encryptedPortion: answer.encryptedPortion || ''
          },
          additional: {
            value: additional.value,
            encrypted: additional.encrypted,
            hash: additional.hash || '',
            encryptedPortion: additional.encryptedPortion || ''
          }
        };
  
        return responseItem;
      })
    };
  
    return response; // Return the object instead of JSON string
  };  

  updateJsonPreview = () => {
    this.setState({
      jsonPreview: this.prepareJsonAndHash(this.props.surveyIndex)
    });
  };

  processJsonToTree = (json, level = 0) => {
    let output = [];

    if (json === null || json === undefined) {
      return output;
    }

    if (Array.isArray(json)) {
      json.forEach((item, index) => {
        if (item !== null && typeof item === 'object') {
          output.push({ type: 'arrayItem', key: index, level });
          output = [...output, ...this.processJsonToTree(item, level + 1)];
        } else {
          output.push({ type: 'arrayItemValue', key: index, value: item, level });
        }
      });
    } else if (typeof json === 'object') {
      Object.keys(json).forEach((key) => {
        if (json[key] !== null && typeof json[key] === 'object') {
          output.push({ type: 'objectKey', key, level });
          output = [...output, ...this.processJsonToTree(json[key], level + 1)];
        } else {
          output.push({ type: 'objectKeyValue', key, value: json[key], level });
        }
      });
    }
    return output;
  };

  jsonTreeDisplay = (jsonInput) => {
    let jsonObject;
    if (typeof jsonInput === 'string') {
      try {
        jsonObject = JSON.parse(jsonInput);
      } catch (e) {
        console.error("Invalid JSON string:", e);
        return null;
      }
    } else if (typeof jsonInput === 'object') {
      jsonObject = jsonInput;
    } else {
      console.error("Invalid input for jsonTreeDisplay");
      return null;
    }
    if (!jsonObject) {
      return null;
    }
    const treeData = this.processJsonToTree(jsonObject);

    return (
      <ul className={styles.tree}>
        {treeData.map((node, index) => (
          <li
            key={index}
            className={styles.treeItem}
            style={{ marginLeft: `${node.level * 20}px` }}
          >
            <span className={styles.keyValueContainer}>
              {node.type === 'arrayItemValue' && (
                <span>[{node.key}]: {String(node.value)}</span>
              )}
              {node.type === 'objectKeyValue' && (
                <span>{node.key}: {String(node.value)}</span>
              )}
              {node.type === 'arrayItem' && <span>[{node.key}]</span>}
              {node.type === 'objectKey' && <span>{node.key}:</span>}
              {node.type === 'value' && <span>{String(node.value)}</span>}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  // Encryption and decryption methods

  getPublicKey = async () => {
    var provider;
    const address = this.props.account;

    if (this.props.provider == "Torus") {
      provider = window.torusProvider
    }
    else { // NOTE: Specify Metamask
      provider = window.ethereum;
    }

    // const signer = provider.getSigner();

    const pubkey = await provider.request({
      "method": "eth_getEncryptionPublicKey",
      "params": [
        address
      ]
    });

    console.log("pubkey: " + pubkey)
    return pubkey;
  };

  encryptDataForPublicKey = async (publicKey, data) => { // 
    // console.log("publicKey: " + publicKey)

    // hardcoded argument: https://github.com/MetaMask/eth-sig-util/blob/67a95bab3f414ed8a5718fcb4beccc6088c0db0e/src/encryption.ts#L40C10-L40C36
    const encrypted = await encrypt({ publicKey: publicKey, data: data, version: 'x25519-xsalsa20-poly1305' });

    // console.log("encrypted: ")
    // console.log(encrypted)

    // formatting is particular for the eth_decrypt functionality: 
    // https://github.com/ethers-io/ethers.js/issues/4139#issuecomment-1615971987
    // https://metamask.github.io/test-dapp/#getEncryptionKeyButton --> https://github.com/MetaMask/test-dapp/blob/261be4fe4b9f7b774064c090faa617d7b756c3d5/src/index.js#L1320
    const encryptedFormatted = `0x${Buffer.from(JSON.stringify(encrypted), 'utf8').toString('hex')}`


    return encryptedFormatted;
  };

  decryptData = async (encryptedData) => {
    // Note: Works for Metamask / Wagmi, would need change if integrating other providers
    const provider = this.props.provider == "Torus" ? window.torusProvider : window.ethereum;

    const hexCypher = encryptedData;

    try {
      const unencryptedData = await provider.request({
        "method": "eth_decrypt",
        "params": [
          hexCypher,
          this.props.account, // address (whose public key data is encrypted for)
        ]
      });

      console.log("unencrypted: " + unencryptedData)
      return unencryptedData;
    } catch (error) {
      console.log("Error - Data Decryption: ")
      console.log(error);
    }
  };

  encryptMultipleAnswers = async (pubKey) => {
    var currentSurvey = this.state.surveysResponseState[this.props.surveyIndex];

    for (let questionId in currentSurvey.answers) {
      const answer = currentSurvey.answers[questionId];
      const additional = currentSurvey.additionalComments[questionId];

      if (answer.encrypted && answer.value !== '*') {
        let dataToEncrypt = typeof answer.value === 'string' ? answer.value : JSON.stringify(answer.value);
        const encryptedData = await this.encryptDataForPublicKey(pubKey, dataToEncrypt);
        answer.encryptedPortion = encryptedData;
        answer.value = '*';
      }

      if (additional && additional.encrypted && additional.value !== '*') {
        let dataToEncrypt = typeof additional.value === 'string' ? additional.value : JSON.stringify(additional.value);
        const encryptedData = await this.encryptDataForPublicKey(pubKey, dataToEncrypt);
        additional.encryptedPortion = encryptedData;
        additional.value = '*';
      }
    }

    const newsurveysResponseState = [...this.state.surveysResponseState];
    newsurveysResponseState[this.props.surveyIndex] = currentSurvey;

    this.setState({ surveysResponseState: newsurveysResponseState });
  };

  decryptMultipleAnswers = async () => {
    var currentSurvey = this.state.surveysResponseState[this.props.surveyIndex];

    for (let questionId in currentSurvey.answers) {
      const answer = currentSurvey.answers[questionId];
      const additional = currentSurvey.additionalComments[questionId];

      if (answer.encryptedPortion) {
        try {
          const decryptedData = await this.decryptData(answer.encryptedPortion);
          let data;
          try {
            data = JSON.parse(decryptedData);
          } catch (e) {
            data = decryptedData;
          }
          answer.value = data;
          answer.encryptedPortion = '';
        } catch (error) {
          console.error("Error decrypting data for question", questionId, error);
        }
      }

      if (additional && additional.encryptedPortion) {
        try {
          const decryptedData = await this.decryptData(additional.encryptedPortion);
          let data;
          try {
            data = JSON.parse(decryptedData);
          } catch (e) {
            data = decryptedData;
          }
          additional.value = data;
          additional.encryptedPortion = '';
        } catch (error) {
          console.error("Error decrypting additional data for question", questionId, error);
        }
      }
    }

    const updatedsurveysResponseState = [...this.state.surveysResponseState];
    updatedsurveysResponseState[this.props.surveyIndex] = currentSurvey;

    this.setState({ surveysResponseState: updatedsurveysResponseState });
  };

  handleDecryptQuestionAnswer = async (questionId) => {
    var currentSurvey = this.state.surveysResponseState[this.props.surveyIndex];
    const answer = currentSurvey.answers[questionId];
    const additional = currentSurvey.additionalComments[questionId];

    if (answer.encryptedPortion) {
      try {
        const decryptedData = await this.decryptData(answer.encryptedPortion);
        const data = JSON.parse(decryptedData);
        answer.value = this.parseAnswerValue(data);
        // Clear encryptedPortion after decryption
        answer.encryptedPortion = '';
      } catch (error) {
        console.error("Error decrypting data for question", questionId, error);
      }
    }

    if (additional && additional.encryptedPortion) {
      try {
        const decryptedData = await this.decryptData(additional.encryptedPortion);
        const data = JSON.parse(decryptedData);
        additional.value = this.parseAnswerValue(data);
        // Clear encryptedPortion after decryption
        additional.encryptedPortion = '';
      } catch (error) {
        console.error("Error decrypting additional data for question", questionId, error);
      }
    }

    const updatedsurveysResponseState = [...this.state.surveysResponseState];
    updatedsurveysResponseState[this.props.surveyIndex] = currentSurvey;
    this.setState({ surveysResponseState: updatedsurveysResponseState });
  };

  encryptData = async () => {
    console.log("encryptData() - invoked");
    if (!this.props.loginComplete) {
      const open = true;
      this.props.toggleLoginModal(open);
      return;
    }

    var pubKey = this.props.pubKey;

    if (this.props.pubKey === '') {
      pubKey = await this.getPublicKey();
      this.props.updatePubKey(pubKey);
    }

    await this.encryptMultipleAnswers(pubKey);

    const jsonData = this.prepareJsonAndHash(this.props.surveyIndex);
    const jsonObject = JSON.parse(jsonData);

    console.log("encrypted jsonObject:", jsonObject);

    this.setState({ jsonPreview: JSON.stringify(jsonObject, null, 2) });
  };

  encryptAndUpload = async () => {
    if (!this.props.loginComplete) {
      const open = true;
      this.props.toggleLoginModal(open);
      return;
    }

    this.setState({ isSubmitting: true, submitProgress: 0, currentStep: 1 });

    try {
      await this.encryptData();
      await this.verifyEncryption();

      this.setState({ currentStep: 2 });

      await this.submitSurveyResponse();

      this.setState({
        isSubmitting: false,
        submitProgress: 100,
        submissionComplete: true,
        currentStep: 3,
      });

      const responseUrl = `${window.location.origin}/survey/${this.props.surveyID}/${this.props.account}`;
      this.setState({ responseUrl });

    } catch (error) {
      console.error("Failed to submit survey:", error);
      this.setState({ isSubmitting: false, submitProgress: 0, submissionError: error.message });
    }
  };

  verifyEncryption = async () => {
    console.log("Verifying encryption...");
    return true;
  };

  submitSurveyResponse = async () => {
    if (!this.props.loginComplete) {
      const open = true;
      this.props.toggleLoginModal(open);
      return;
    }
  
    const surveyAnswersData = this.prepareJsonAndHash(this.props.surveyIndex);
    console.log("surveyAnswersData:", surveyAnswersData);
  
    let arweaveHash = '';
  
    if (ARWEAVE_ACTIVE) {
      const jsonSurveyAnswersString = JSON.stringify(surveyAnswersData);
      arweaveHash = await contractScripts.uploadDataToArweave(jsonSurveyAnswersString, 'json');
      console.log("arweaveHash:", arweaveHash);
    } else {
      console.log("Arweave is not active. Survey response will not be uploaded.");
      return;
    }
  
    const arweaveHashBytes = contractScripts.base64urlToHex(arweaveHash);
    const arweaveHashBytesString = arweaveHashBytes.toString();
    console.log("arweaveHashBytesString:", arweaveHashBytesString);
  
    const surveyID = this.props.surveyID;
    console.log("surveyID:", surveyID);
  
    const response = await contractScripts.submitResponses(
      this.props.provider,
      surveyAnswersData.responses.map(r => r.questionID),
      surveyAnswersData.responses,
      surveyID,
      surveyAnswersData
    );
  
    console.log("response:", response);
    if (response.status === 1) {
      console.log("Survey response submitted successfully!");
    } else {
      console.log("Error submitting survey response.");
    }
  };  

  renderQuestion = (question, qIndex, currentSurveyResponseState) => {
    if (!question || !question.id || !question.type) {
      console.error('Invalid question data at index:', qIndex);
      return null;
    }

    const surveyIndex = this.props.isStandalone ? 0 : this.props.surveyIndex;
    const answer = currentSurveyResponseState.answers[question.id] || { value: '', encrypted: false };

    // Check if the answer is encrypted and needs decryption
    if (answer.encrypted && answer.value === '*') {
      return (
        <Card key={question.id}>
          <CardBody id={styles.questionTitleBody}>
            <h4 id={styles.questionTitle}>
              {question.prompt}
            </h4>
            <p>This answer is encrypted. Please decrypt to view and edit.</p>
            <Button onClick={() => this.handleDecryptQuestionAnswer(question.id)} id={styles.decryptQuestionButton}>
              Decrypt Answer
            </Button>
          </CardBody>
        </Card>
      );
    } else {
      let questionComponent;

      switch (question.type) {
        case 'multichoice':
          questionComponent = (
            <FormGroup id={styles.multiChoice}>
              {question.options.map((option, oIndex) => (
                <Label check key={oIndex} className={styles.checkboxOptionText}>
                  <Input
                    type="checkbox"
                    name={`question-${question.id}`}
                    value={option}
                    onChange={(e) => {
                      const currentAnswer = currentSurveyResponseState.answers[question.id]?.value || [];
                      const newAnswer = [...currentAnswer];
                      if (e.target.checked) {
                        newAnswer.push(option);
                      } else {
                        const index = newAnswer.indexOf(option);
                        if (index > -1) {
                          newAnswer.splice(index, 1);
                        }
                      }
                      this.handleAnswer(surveyIndex, question.id, newAnswer);
                    }}
                    checked={currentSurveyResponseState.answers[question.id]?.value?.includes(option) || false}
                  />
                  {option}
                </Label>
              ))}
            </FormGroup>
          );
          break;
        case 'rating':
          questionComponent = (
            <>
              <div className={styles.importanceSlider}>
                <Slider
                  min={0}
                  max={10}
                  step={1}
                  value={currentSurveyResponseState.answers[question.id]?.value || 0}
                  tooltip={false}
                  onChange={(ratingAnswer) => this.handleAnswer(surveyIndex, question.id, ratingAnswer)}
                  id={styles.ratingSlider}
                  style={{ width: '200px' }}
                />
              </div>
              <FormText id={styles.ratingLabelText}>
                {currentSurveyResponseState.answers[question.id]?.value || 0}
              </FormText>
            </>
          );
          break;
        case 'binary':
          questionComponent = (
            <FormGroup id={styles.binaryChoice}>
              {['Agree', 'Pass', 'Disagree'].map((option, oIndex) => (
                <Label check key={oIndex} className={`${styles.radioOptionText} ${styles[option.toLowerCase()]} ${currentSurveyResponseState.answers[question.id]?.value === option ? styles.selected : ''}`}>
                  <Input
                    type="radio"
                    name={`question-${question.id}`}
                    value={option}
                    checked={currentSurveyResponseState.answers[question.id]?.value === option || false}
                    onChange={() => this.handleAnswer(surveyIndex, question.id, option)}
                  />
                  {option === 'Agree' && <FontAwesomeIcon icon={faCheck} className={styles.optionIcon} />}
                  {option === 'Disagree' && <FontAwesomeIcon icon={faTimes} className={styles.optionIcon} />}
                  {option}
                </Label>
              ))}
            </FormGroup>
          );
          break;
        default: // 'freeform'
          questionComponent = (
            <AudioInput
              qIndex={qIndex}
              placeholder={'response (optional)'}
              updateFunction={(answer) => this.handleAnswer(surveyIndex, question.id, answer)}
              toggleEncryption={(newEncryptedState) => this.toggleAnswerEncryption(surveyIndex, question.id, newEncryptedState)}
              value={currentSurveyResponseState.answers[question.id]?.value || ''}
              encrypted={currentSurveyResponseState.answers[question.id]?.encrypted || false}
            />
          );
          break;
      }

      return (
        <Card key={question.id}>
          <CardBody id={styles.questionTitleBody}>
            <h4 id={styles.questionTitle}>
              {question.prompt}
            </h4>
            <InputGroup>
              {questionComponent}
              {question.type !== 'freeform' && (
                <InputGroupAddon addonType="append" id={styles.encryptAdditionalThoughts}>
                  <div id={styles.encryptOptionButton} onClick={() => this.toggleAnswerEncryption(surveyIndex, question.id, !currentSurveyResponseState.answers[question.id]?.encrypted)}>
                    <FontAwesomeIcon id={styles.encryptIcon} icon={currentSurveyResponseState.answers[question.id]?.encrypted ? faLock : faUnlock} />
                    <InputGroupText id={styles.inputGroupTextBox}>
                      <Input
                        addon
                        type="checkbox"
                        aria-label="encrypt"
                        checked={currentSurveyResponseState.answers[question.id]?.encrypted || false}
                        onChange={() => this.toggleAnswerEncryption(surveyIndex, question.id, !currentSurveyResponseState.answers[question.id]?.encrypted)}
                        id={styles.encryptCheckbox}
                      />
                      Encrypt
                    </InputGroupText>
                  </div>
                </InputGroupAddon>
              )}
            </InputGroup>
            <div className={styles.importanceSlider}>
              <h6 id={styles.importanceText}>Importance: {currentSurveyResponseState.importance[question.id] || 0} </h6>
              <Slider
                min={0}
                max={10}
                step={1}
                value={currentSurveyResponseState.importance[question.id] || 0}
                tooltip={false}
                onChange={(importance) => this.handleImportance(surveyIndex, question.id, importance)}
              />
            </div>
            <AudioInput
              qIndex={qIndex}
              updateFunction={(additionalComments) => this.handleAdditional(surveyIndex, question.id, additionalComments)}
              toggleEncryption={(newEncryptedState) => this.toggleAdditionalCommentsEncryption(surveyIndex, question.id, newEncryptedState)}
              placeholder={'related thoughts or URLs (optional)'}
              value={currentSurveyResponseState.additionalComments[question.id]?.value || ''}
              encrypted={currentSurveyResponseState.additionalComments[question.id]?.encrypted || false}
            />
          </CardBody>
        </Card>
      );
    }
  };

  render() {
    const surveyIndex = this.props.isStandalone ? 0 : this.props.surveyIndex;
    const currentSurveyResponseState = this.state.surveysResponseState[surveyIndex];
    const viewingAnswers = this.state.displayAnswerMode;
    const jsonPreview = this.state.jsonPreview || this.prepareJsonAndHash(surveyIndex);

    if (!currentSurveyResponseState) {
      console.error('Current survey response state is undefined.');
      return null;
    }

    let jsonDisplay = null;
    if (jsonPreview) {
      jsonDisplay = this.jsonTreeDisplay(jsonPreview);
    }

    if (viewingAnswers) {
      if (this.state.noResponse) {
        jsonDisplay = this.jsonTreeDisplay(
          { message: "No response for this survey from address: " + this.props.viewAddress }
        );
      } else if (this.state.viewAddressAnswers !== '') {
        jsonDisplay = this.jsonTreeDisplay(JSON.parse(this.state.viewAddressAnswers));
      } else {
        jsonDisplay = null;
      }
    } else {
      const jsonPreview = this.state.jsonPreview || this.prepareJsonAndHash(surveyIndex);
      if (jsonPreview) {
        jsonDisplay = this.jsonTreeDisplay(jsonPreview);
      } else {
        jsonDisplay = null;
      }
    }

    const notClickable = false;

    const shortenedViewAddress = this.props.viewAddress ?
      proposalScripts.getShortenedAddress(this.props.viewAddress, notClickable) : '';

    const viewAnswersButton = this.props.viewAddress && this.props.viewAddress !== this.props.account ? (
      <Button onClick={this.toggleDisplayAnswerMode} id={styles.answerSurveyButton}>
        <FontAwesomeIcon icon={viewingAnswers ? faPlus : faArrowLeft} id={styles.encryptIcon} />
        <div id={styles.surveyButtonText}>
          {viewingAnswers ? " Fill out survey" : ` View ${shortenedViewAddress} answers`}
        </div>
      </Button>
    ) : null;

    const userResponseNotice = this.state.userHasResponse ? (
      <div className={styles.userResponseNotice}>
        <p>Pre-existing survey response detected</p>
        <Button onClick={this.handleStartFresh} id={styles.startFreshButton}>
          Start Fresh
        </Button>
        {this.state.userResponseEncrypted ? (
          <Button onClick={this.handleDecryptEdit} id={styles.decryptEditButton} disabled={this.state.isDecrypting}>
            {this.state.isDecrypting ? "Decrypting..." : "Decrypt / Edit"}
          </Button>
        ) : (
          <Button onClick={this.handleUpdateResponse} id={styles.editButton}>
            {this.state.isEditing ? "Update Response" : "Edit Response"}
          </Button>
        )}
        {!viewingAnswers && this.props.viewAddress && (
          <Button onClick={this.toggleDisplayAnswerMode} id={styles.viewAnswersButton}>
            {`See ${shortenedViewAddress} Answers`}
          </Button>
        )}
      </div>
    ) : null;

    const anyEncryptedAnswers = Object.values(currentSurveyResponseState.answers || {}).some(answer => answer.encrypted) ||
      Object.values(currentSurveyResponseState.additionalComments || {}).some(comment => comment.encrypted);

    const submitButtonText = anyEncryptedAnswers ? "Encrypt / Upload" : "Upload";

    return (
      <div>
        {viewAnswersButton}
        {userResponseNotice}
        {(!viewingAnswers && (!this.state.userHasResponse || this.state.startFresh || this.state.isEditing)) && (
          <>
            {this.state.questionPool.map((question, qIndex) => this.renderQuestion(question, qIndex, currentSurveyResponseState))}
            <div className={styles.footer} id={styles.surveyFooter}>
              <Button id={styles.submitSurveyButton} onClick={this.encryptAndUpload} disabled={this.state.isSubmitting}>
                {this.state.isSubmitting ? (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '10px' }} />
                    Uploading Survey...
                  </div>
                ) : this.state.submissionComplete ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  submitButtonText
                )}
              </Button>
            </div>
            {/* Toggle JSON button */}
            <Button className={styles.toggleJsonButton} onClick={this.toggleShowJson}>
              {this.state.showJson ? "Hide JSON" : "Show JSON"}
            </Button>

            {/* Conditionally display JSON */}
            {this.state.showJson && jsonDisplay && (
              <pre id={styles.responsePreview}>
                {jsonDisplay}
              </pre>
            )}
            {(this.state.isSubmitting || this.state.submissionComplete || this.state.submissionError) && (
              <div className={styles.submissionStatusBox}>
                {this.state.isSubmitting && (
                  <div className={styles.submissionStatus}>
                    {this.state.currentStep === 1 && (
                      <div>
                        <FontAwesomeIcon icon={faSpinner} spin /> Encrypting Data...
                      </div>
                    )}
                    {this.state.currentStep === 2 && (
                      <div>
                        <FontAwesomeIcon icon={faSpinner} spin /> Uploading Response...
                      </div>
                    )}
                  </div>
                )}
                {this.state.submissionComplete && (
                  <div className={styles.submissionStatus}>
                    <FontAwesomeIcon icon={faCheck} /> Survey response submitted successfully!
                    {this.state.responseUrl && (
                      <div>
                        <p>View your response here:</p>
                        <a href={this.state.responseUrl} target="_blank" rel="noopener noreferrer">
                          {this.state.responseUrl}
                        </a>
                      </div>
                    )}
                  </div>
                )}
                {this.state.submissionError && (
                  <div className={styles.submissionStatusError}>
                    <FontAwesomeIcon icon={faExclamationCircle} /> Error: {this.state.submissionError}
                  </div>
                )}
              </div>
            )}
          </>
        )}
       {viewingAnswers && jsonDisplay && (
          <pre id={styles.responsePreview}>
            {jsonDisplay}
          </pre>
        )}
      </div>
    );
  }
}

class CreateSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      questions: [],
      addingQuestionType: 'Question Type',
      surveySubmitted: false,
      surveyHash: '',
      isSubmitting: false,
      progress: 0,
      showJson: false,
      isStandaloneQuestion: false,
      associatedSurveyId: '', // Start blank
      surveyAddedSuccessfully: false,
      questionsAddedSuccessfully: false,
      uploadedQuestions: [],
      submissionError: '', // Added for error handling
    };
  }

  componentDidMount() {
    this.loadFromLocalStorage();
    // this.getCurrentSurveyIdFromUrl();
    this.setState({
      isSubmitting: false,
      progress: 0,
      submissionError: '',
    });
  }

  getSurveyIdFromUrl = () => {
    const pathParts = window.location.pathname.split('/');
    const surveyIndex = pathParts.indexOf('survey');
    if (surveyIndex !== -1 && pathParts.length > surveyIndex + 1) {
      return pathParts[surveyIndex + 1];
    }
    return null;
  };

  loadFromLocalStorage = () => {
    const savedSurvey = localStorage.getItem('unfinishedSurvey');
    if (savedSurvey) {
      try {
        const parsedSurvey = JSON.parse(savedSurvey);
        // Ensure that questions have 'options' if they are multichoice
        if (parsedSurvey.questions && Array.isArray(parsedSurvey.questions)) {
          parsedSurvey.questions = parsedSurvey.questions.map(q => ({
            ...q,
            options: q.type === 'multichoice' && !Array.isArray(q.options) ? [] : q.options,
          }));
        }
        this.setState(parsedSurvey, this.updateSurveyHash);
      } catch (error) {
        console.error('Error parsing saved survey from localStorage:', error);
      }
    }
  };

  saveToLocalStorage = () => {
    localStorage.setItem('unfinishedSurvey', JSON.stringify(this.state));
  };

  generateQuestionId = (type, prompt, options = []) => {
    let dataToHash = `${type}:${prompt.toLowerCase()}`;
    if (type === 'multichoice' && options.length > 0) {
      dataToHash += `:${options.join(',').toLowerCase()}`;
    }
    const hash = sha256(dataToHash).toString();
    return '0x' + hash;
  };

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  addQuestion = () => {
    const type = this.state.addingQuestionType;
    if (!type || type === 'Question Type') return;

    const newQuestionId = this.generateQuestionId(type, '', []); // Empty prompt and options initially
    const newQuestionData = {
      id: newQuestionId,
      type: type,
      prompt: '',
      options: type === "multichoice" ? [] : undefined,
      associatedSurveyId: '',
    };

    this.setState(prevState => ({
      questions: [...prevState.questions, newQuestionData],
      addingQuestionType: 'Question Type'
    }), () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  handleQuestionChange = (index, key, value) => {
    const { questions } = this.state;
    const updatedQuestions = [...questions];
    const question = { ...updatedQuestions[index], [key]: value };

    // If the prompt, type, or options change, regenerate the question ID
    const options = question.options || [];
    question.id = this.generateQuestionId(question.type, question.prompt, options);

    updatedQuestions[index] = question;

    this.setState({ questions: updatedQuestions }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  handleOptionChange = (questionIndex, optionIndex, value) => {
    const { questions } = this.state;
    const question = { ...questions[questionIndex] };

    // Ensure options array exists
    if (!Array.isArray(question.options)) {
      question.options = [];
    }

    const updatedOptions = [...question.options];
    updatedOptions[optionIndex] = value;

    // Update question options and regenerate question ID
    const updatedQuestion = { ...question, options: updatedOptions };
    updatedQuestion.id = this.generateQuestionId(updatedQuestion.type, updatedQuestion.prompt, updatedOptions);

    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex] = updatedQuestion;

    this.setState({ questions: updatedQuestions }, () => {
      this.saveToLocalStorage();
    });
  };

  addOption = (questionIndex) => {
    const { questions } = this.state;
    const question = { ...questions[questionIndex] };

    // Ensure options array exists
    if (!Array.isArray(question.options)) {
      question.options = [];
    }

    const updatedOptions = [...question.options, ''];

    const updatedQuestion = { ...question, options: updatedOptions };
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex] = updatedQuestion;

    this.setState({ questions: updatedQuestions }, () => {
      this.saveToLocalStorage();
    });
  };

  removeOption = (questionIndex, optionIndex) => {
    const { questions } = this.state;
    const question = { ...questions[questionIndex] };

    // Ensure options array exists
    if (!Array.isArray(question.options)) {
      question.options = [];
    }

    const updatedOptions = question.options.filter((_, i) => i !== optionIndex);

    const updatedQuestion = { ...question, options: updatedOptions };
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex] = updatedQuestion;

    this.setState({ questions: updatedQuestions }, () => {
      this.saveToLocalStorage();
    });
  };

  removeQuestion = (index) => {
    const { questions } = this.state;

    const updatedQuestions = questions.filter((_, i) => i !== index);

    this.setState({ questions: updatedQuestions }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  bookmarkQuestion = (questionId) => {
    let bookmarkedQuestions = JSON.parse(localStorage.getItem('bookmarkedQuestions')) || [];
    if (!bookmarkedQuestions.includes(questionId)) {
      bookmarkedQuestions.push(questionId);
      localStorage.setItem('bookmarkedQuestions', JSON.stringify(bookmarkedQuestions));
      console.log(`Question ${questionId} bookmarked successfully.`);
    } else {
      console.log(`Question ${questionId} is already bookmarked.`);
    }
  };

  updateSurveyHash = () => {
    const { title, isStandaloneQuestion } = this.state;
    if (isStandaloneQuestion) {
      this.setState({ surveyHash: '' });
    } else {
      const surveyData = { title };
      const newSurveyHash = "0x" + sha256(JSON.stringify(surveyData)).toString();
      this.setState({ surveyHash: newSurveyHash });
    }
  };

  toggleStandaloneQuestion = () => {
    this.setState(prevState => ({
      isStandaloneQuestion: !prevState.isStandaloneQuestion,
      title: '',
      questions: [],
    }), () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  handleAssociatedSurveyIdChange = (index, value) => {
    const { questions } = this.state;
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...updatedQuestions[index], associatedSurveyId: value };
    this.setState({ questions: updatedQuestions }, () => {
      this.saveToLocalStorage();
    });
  };

  addCurrentSurvey = (index) => {
    const surveyIdFromUrl = this.getSurveyIdFromUrl();
    if (!surveyIdFromUrl) {
      alert('No survey ID found in URL.');
      return;
    }

    const { questions } = this.state;
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...updatedQuestions[index], associatedSurveyId: surveyIdFromUrl };
    this.setState({ questions: updatedQuestions }, () => {
      this.saveToLocalStorage();
    });
  };


  createSurvey = async () => {
    console.log("createSurvey function called");
  
    if (!this.props.loginComplete) {
      console.log("Login not complete. Please log in to create a survey");
      this.props.toggleLoginModal(true);
      return;
    }
  
    console.log("Login complete. Proceeding with survey creation.");
    this.setState({ isSubmitting: true, progress: 0 });
  
    const { title, questions, isStandaloneQuestion, surveyHash } = this.state;
    console.log("Survey State Values:", { title, questions, surveyHash });
  
    try {
      if (isStandaloneQuestion) {
        // Handle standalone questions
        if (questions.length === 0) {
          alert("Please add at least one question.");
          this.setState({ isSubmitting: false, progress: 0 });
          return;
        }
  
        this.setState({ progress: 10 });
  
        const arweaveHashes = [];
        const surveyIds = [];
        const uploadedQuestions = []; // To store mapping of questionId to arweaveTxId
  
        for (const question of questions) {
          const questionData = {
            type: question.type,
            prompt: question.prompt,
            id: question.id,
            associatedSurveyId: question.associatedSurveyId || '',
            tags: question.tags || [],
            options: question.type === "multichoice" ? question.options : undefined
          };
  
          // Upload each question individually to Arweave
          const arweaveHash = await contractScripts.uploadDataToArweave(JSON.stringify(questionData), 'json');
          arweaveHashes.push(arweaveHash);
  
          // Store mapping
          uploadedQuestions.push({ questionId: question.id, arweaveTxId: arweaveHash });
  
          // For associatedSurveyId, use question.associatedSurveyId or ethers.constants.HashZero
          const surveyId = question.associatedSurveyId || ethers.constants.HashZero;
          surveyIds.push(surveyId);
        }
  
        this.setState({ progress: 50 });
  
        // Now, submit the questions via contractScripts.addQuestions
        const questionIds = questions.map(q => q.id);
  
        const response = await contractScripts.addQuestions(
          this.props.provider,
          questionIds,
          arweaveHashes,
          surveyIds
        );
  
        console.log("Questions submitted successfully:", response);
  
        this.setState({
          questionsAddedSuccessfully: true,
          isSubmitting: false,
          progress: 100,
          uploadedQuestions: uploadedQuestions // Store the uploaded questions in state
        });
  
        localStorage.removeItem('unfinishedSurvey');
      } else {
        if (!title.trim()) {
          alert("Please enter a survey title.");
          this.setState({ isSubmitting: false, progress: 0 });
          return;
        }
  
        // Step 1: Calculate surveyHash without questionIDs
        const surveyData = { title };
        const newSurveyHash = "0x" + sha256(JSON.stringify(surveyData)).toString();
        this.setState({ surveyHash: newSurveyHash }, async () => {
          // Step 2: Assign surveyHash to each question's associatedSurveyId
          const questionDataArray = questions.map(q => {
            return {
              ...q,
              tags: q.tags || [],
              associatedSurveyId: newSurveyHash
            };
          });
  
          // Step 3: Prepare surveyData with questionIDs
          const completeSurveyData = {
            surveyID: newSurveyHash,
            title: title,
            questionIDs: questions.map(q => q.id)
          };
  
          // Step 4: Submit survey and questions to the smart contract
          const response = await contractScripts.addSurveyWithQuestions(
            this.props.provider,
            newSurveyHash,
            completeSurveyData,
            questions.map(q => q.id),
            questionDataArray
          );
  
          console.log("Survey submitted successfully:", response);
          this.setState({
            surveyAddedSuccessfully: true,
            isSubmitting: false,
            progress: 100,
            surveyHash: newSurveyHash,
          });
  
          localStorage.removeItem('unfinishedSurvey');
        });
      }
    }  catch (error) {
      console.error("Failed to create survey:", error);
      this.setState({
        isSubmitting: false,
        progress: 0,
        submissionError: error.message || 'An error occurred while creating the survey.',
      });
    }
  };  


  toggleShowJson = () => {
    this.setState(prevState => ({ showJson: !prevState.showJson }));
  };

  copyQuestionIdToClipboard = (questionId) => {
    navigator.clipboard.writeText(questionId)
      .then(() => {
        console.log(`Successfully copied Question ID: ${questionId}`);
      })
      .catch((err) => {
        console.error('Failed to copy Question ID: ', err);
      });
  };

  copySurveyLinkToClipboard = () => {
    const surveyLink = `${window.location.origin}/survey/${this.state.surveyHash}`;
    navigator.clipboard.writeText(surveyLink)
      .then(() => {
        console.log(`Successfully copied ${surveyLink}`);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  render() {
    const {
      title, questions, isSubmitting, progress,
      showJson, isStandaloneQuestion, associatedSurveyId,
      surveyAddedSuccessfully, questionsAddedSuccessfully,
      uploadedQuestions, submissionError // Extracted from state
    } = this.state;

    const surveyID = isStandaloneQuestion ? '' : this.state.surveyHash;

    const questionTypeTooltips = {
      freeform: "Allows respondents to enter any text as an answer.",
      rating: "Lets respondents rate on a scale, typically from 0 to 10.",
      multichoice: "Provides multiple options for respondents to choose from.",
      binary: "Presents an agree/disagree or yes/no type question."
    };

    // Prepare the JSON data for preview
    let jsonData = {};
    if (isStandaloneQuestion) {
      jsonData = {
        questions: questions.map(q => ({
          prompt: q.prompt,
          type: q.type,
          id: q.id,
          associatedSurveyId: q.associatedSurveyId || '',
          tags: q.tags || [],
          ...(q.type === "multichoice" && { options: q.options })
        }))
      };
    } else {
      jsonData = {
        surveyID: surveyID,
        title: title,
        questionIDs: questions.map(q => q.id),
        questions: questions.map(q => ({
          prompt: q.prompt,
          type: q.type,
          id: q.id,
          tags: q.tags || [],
          ...(q.type === "multichoice" && { options: q.options })
        }))
      };
    }

    return (
      <div className={styles.createSurveyContainer}>
        <div className={styles.modeToggle}>
          <Label className={styles.toggleLabel}>Create Survey</Label>
          <div
            className={styles.toggleSwitch}
            onClick={this.toggleStandaloneQuestion}
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              cursor: 'pointer',
              backgroundColor: '#ccc',
              borderRadius: '15px',
              position: 'relative',
              width: '60px',
              height: '30px',
              verticalAlign: 'middle',
            }}
          >
            <div
              style={{
                backgroundColor: isStandaloneQuestion ? '#4caf50' : '#fff',
                borderRadius: '50%',
                width: '28px',
                height: '28px',
                position: 'absolute',
                top: '1px',
                left: isStandaloneQuestion ? '31px' : '1px',
                transition: 'left 0.2s',
              }}
            />
          </div>
          <Label className={styles.toggleLabel} style={{ marginLeft: '10px' }}>Add Question(s)</Label>
        </div>

        {!isStandaloneQuestion && (
          <Input
            className={styles.surveyTitleInput}
            placeholder="Survey Title (required)"
            value={title}
            onChange={this.handleTitleChange} // Ensure onChange is present
            required
          />
        )}

        {questions.map((question, qIndex) => (
          <div key={qIndex} className={styles.questionContainer}>
            <div className={styles.questionHeader}>
              <strong className={styles.questionTypeText}>#{qIndex + 1}: {question.type.charAt(0).toUpperCase() + question.type.slice(1)} Question</strong>
              <Button className={styles.removeQuestionButton} onClick={() => this.removeQuestion(qIndex)}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
            <Input
              className={styles.questionPromptInput}
              placeholder="Question prompt"
              value={question.prompt}
              onChange={e => this.handleQuestionChange(qIndex, 'prompt', e.target.value)} // Ensure onChange is present
            />
            {question.type === "multichoice" && (
              <div className={styles.optionsContainer}>
                {question.options.map((option, oIndex) => (
                  <div key={oIndex} className={styles.optionItem}>
                    <Input
                      placeholder="Option"
                      value={option}
                      onChange={e => this.handleOptionChange(qIndex, oIndex, e.target.value)} // Ensure onChange is present
                      className={styles.optionInput}
                    />
                    <Button className={styles.removeOptionButton} onClick={() => this.removeOption(qIndex, oIndex)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                ))}
                {question.options.length < 10 && (
                  <Button className={styles.addOptionButton} onClick={() => this.addOption(qIndex)}>
                    <FontAwesomeIcon icon={faPlus} /> Add Option
                  </Button>
                )}
              </div>
            )}
            <div className={styles.questionMetadata}>
              <div className={styles.questionId}>Question ID: {question.id || 'Generating...'}</div>
              {isStandaloneQuestion && (
                <div className={styles.associatedSurveyContainer}>
                  <Label className={styles.associatedSurveyLabel}>
                    Associated Survey:
                    <span
                      className={styles.addCurrentLink}
                      onClick={() => this.addCurrentSurvey(qIndex)}
                      style={{ cursor: associatedSurveyId ? 'pointer' : 'not-allowed', opacity: associatedSurveyId ? 1 : 0.5 }}
                    >
                      (Add Current)
                    </span>
                  </Label>
                  <Input
                    className={styles.associatedSurveyInput}
                    placeholder="Optional"
                    value={question.associatedSurveyId}
                    onChange={e => this.handleAssociatedSurveyIdChange(qIndex, e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
        ))}

        <div className={styles.addQuestionContainer}>
          <select
            className={styles.questionTypeSelect}
            value={this.state.addingQuestionType}
            onChange={e => this.setState({ addingQuestionType: e.target.value })}
          >
            <option value="Question Type">Question Type</option>
            {Object.entries(questionTypeTooltips).map(([type, tooltip]) => (
              <option key={type} value={type} title={tooltip}>
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </option>
            ))}
          </select>
          <Button className={styles.addQuestionButton} onClick={this.addQuestion}>
            <FontAwesomeIcon icon={faPlus} /> Add Question(s)
          </Button>
        </div>

        {!isStandaloneQuestion && (
          <p className={styles.surveyIdPreview}>Survey ID: {surveyID || 'Generating...'}</p>
        )}

        <Button
          className={`${styles.createSurveyButton} ${submissionError ? styles.errorButton : ''}`}
          onClick={this.createSurvey}
          disabled={isSubmitting || (!this.state.isStandaloneQuestion && !this.state.title.trim()) || this.state.questions.length === 0}
        >
          {isSubmitting ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin /> Creating...
            </>
          ) : (
            this.state.isStandaloneQuestion ? "Add Questions" : "Create Survey"
          )}
        </Button>

        {submissionError && (
          <div className={styles.errorMessage}>
            {submissionError}
          </div>
        )}

        {questionsAddedSuccessfully && (
          <div className={styles.surveySubmissionConfirmation}>
            <h3>Questions Added Successfully!</h3>
            {/* Display uploaded questions with links and icons */}
            {uploadedQuestions && uploadedQuestions.length > 0 && (
              <div className={styles.uploadedQuestionsList}>
                <h4>Uploaded Questions:</h4>
                <ul>
                  {uploadedQuestions.map(({ questionId, arweaveTxId }, index) => (
                    <li key={index} className={styles.uploadedQuestionItem}>
                      <a href={`https://arweave.net/${arweaveTxId}`} target="_blank" rel="noopener noreferrer">
                        Question ID: {questionId}
                      </a>
                      <Button className={styles.bookmarkQuestionButton} onClick={() => this.bookmarkQuestion(questionId)}>
                        <FontAwesomeIcon icon={faBookmark} />
                      </Button>
                      <Button className={styles.copyQuestionIdButton} onClick={() => this.copyQuestionIdToClipboard(questionId)}>
                        <FontAwesomeIcon icon={faClipboard} />
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {surveyAddedSuccessfully && (
          <div className={styles.surveySubmissionConfirmation}>
            <h3>Survey Created Successfully!</h3>
            <p>
              Survey ID: {surveyID}
              <Button className={styles.copySurveyIdButton} onClick={this.copySurveyIdToClipboard}>
                <FontAwesomeIcon icon={faClipboard} />
              </Button>
            </p>
            <div className={styles.surveyActions}>
              <Button className={styles.copySurveyLinkButton} onClick={this.copySurveyLinkToClipboard}>
                <FontAwesomeIcon icon={faCheck} className={styles.surveySubmittedIcon} />
                Copy Survey Link
              </Button>
              <a href={`/survey/${surveyID}`} target="_blank" rel="noopener noreferrer" className={styles.viewSurveyLink}>
                View Survey
              </a>
            </div>
          </div>
        )}

        <Button className={styles.toggleJsonButton} onClick={this.toggleShowJson}>
          {showJson ? "Hide JSON" : "Show JSON"}
        </Button>

        {showJson && (
          <pre className={styles.jsonDisplay}>
            {JSON.stringify(jsonData, null, 2)}
          </pre>
        )}
      </div>
    );
  }
}

class SurveyResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responses: [],
      csvData: '',
      filter: '',
      exportType: 'CSV',
      activeToggles: {},
      modalOpen: true,
      sbtFilter: '',
      showAdvancedFilter: false,
      alertMessage: '',
      loading: true,
      viewMode: 'survey', // 'survey' or 'questions'
      questionResponses: {},
    };
  }

  async componentDidMount() {
    this.fetchResponses();
  }

  fetchResponses = async () => {
    this.setState({ loading: true });
    try {
      if (this.state.viewMode === 'survey') {
        const responses = await contractScripts.fetchAllSurveyResponses(this.props.provider, this.props.surveyId);
        const latestResponses = this.getLatestResponses(responses);
        this.setState({ responses: latestResponses, loading: false }, this.generateCSV);
      } else {
        const questionResponses = await this.fetchQuestionResponses();
        this.setState({ questionResponses, loading: false });
      }
    } catch (error) {
      console.error('Error fetching responses:', error);
      this.setState({ loading: false, alertMessage: 'Error fetching responses. Please try again.' });
    }
  };

  fetchQuestionResponses = async () => {
    const { surveyId } = this.props;
    const survey = this.props.cache.arweaveContent[surveyId];
    if (!survey || !survey.questionIDs) {
      throw new Error('Survey or question IDs not found');
    }

    const questionResponses = {};
    for (const questionId of survey.questionIDs) {
      const responses = await contractScripts.getResponsesByQuestionID(this.props.provider, questionId);
      questionResponses[questionId] = responses;
    }
    return questionResponses;
  };

  getLatestResponses(responses) {
    const addressMap = new Map();
    responses.forEach(response => {
      const existingResponse = addressMap.get(response.responder);
      if (!existingResponse || existingResponse.timestamp < response.timestamp) {
        addressMap.set(response.responder, response);
      }
    });
    return Array.from(addressMap.values());
  }

  parseResponse(responseData) {
    try {
      return typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
    } catch (error) {
      console.error('Error parsing response data:', error);
      return null;
    }
  }

  generateCSV = () => {
    const { responses } = this.state;
    if (!responses.length) {
      console.log('No responses to generate CSV');
      return;
    }

    const header = 'responderAddress,questionID,question,type,importance,answer,answerHash,additionalComments,answerEncrypted,additionalEncrypted,additionalHash\n';
    const csvRows = [];

    responses.forEach(response => {
      const parsedResponse = this.parseResponse(response.response);
      if (parsedResponse && parsedResponse.responses) {
        parsedResponse.responses.forEach(answer => {
          const row = [
            response.responder,
            answer.questionID || '',
            answer.question || '',
            answer.type || '',
            answer.importance || '',
            answer.answer ? answer.answer.value : '',
            answer.answer ? answer.answer.hash : '',
            answer.additional ? answer.additional.value : '',
            answer.answer ? answer.answer.encrypted : '',
            answer.additional ? answer.additional.encrypted : '',
            answer.additional ? answer.additional.hash : ''
          ]
            .map(value => `"${value !== undefined ? value : ''}"`)
            .join(',');
          csvRows.push(row);
        });
      }
    });

    const csvData = header + csvRows.join('\n');
    this.setState({ csvData });
  };

  downloadCSV = () => {
    const { csvData, exportType } = this.state;
    let dataToDownload = csvData;

    if (exportType === 'Polis Report') {
      dataToDownload = this.generatePolisReport();
    } else if (exportType === 'Talk To The City Report') {
      dataToDownload = this.generateTalkToTheCityReport();
    }

    const blob = new Blob([dataToDownload], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `survey_responses_${this.props.surveyId}_${exportType}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Instead of alert, set the alertMessage state
    if (exportType === 'Polis Report') {
      this.setState({
        alertMessage: 'Note: Only Binary (Agree / Disagree) question types are used in the Polis Report.',
      });
    } else {
      this.setState({ alertMessage: '' });
    }
  };

  generatePolisReport = () => {
    // Placeholder for actual implementation
    return 'Polis Report Data';
  };

  generateTalkToTheCityReport = () => {
    // Placeholder for actual implementation
    return 'Talk To The City Report Data';
  };

  handleFilterChange = e => {
    this.setState({ filter: e.target.value });
  };

  handleSBTFilterChange = e => {
    this.setState({ sbtFilter: e.target.value });
  };

  handleExportTypeChange = type => {
    this.setState({ exportType: type });
  };

  toggleResponse = index => {
    this.setState(prevState => ({
      activeToggles: {
        ...prevState.activeToggles,
        [index]: !prevState.activeToggles[index]
      }
    }));
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  toggleAdvancedFilter = () => {
    this.setState(prevState => ({ showAdvancedFilter: !prevState.showAdvancedFilter }));
  };

  renderResponseContent = response => {
    const parsedResponse = this.parseResponse(response.response);
    if (!parsedResponse) {
      return <div>No response data available</div>;
    }

    const { timeStamp, responses } = parsedResponse;
    const formattedTimestamp = new Date(parseInt(timeStamp)).toLocaleString();



    return (
      <div>
        <p>
          <strong>Timestamp:</strong> {formattedTimestamp}
        </p>
        {responses &&
          responses.map((item, index) => (
            <div key={index} className={styles.responseItem}>
              <h4 className={styles.questionTitle}>{item.question}</h4>
              <p>
                <strong>Question Type:</strong> {item.type}
              </p>
              <p>
                <strong>Importance:</strong> {item.importance}
              </p>
              <p>
                <strong>Answer:</strong> {item.answer.value}
              </p>
              <p>
                <strong>Answer Encrypted:</strong> {item.answer.encrypted.toString()}
              </p>
              {item.additional && item.additional.value && (
                <>
                  <p>
                    <strong>Additional Comments:</strong> {item.additional.value}
                  </p>
                  <p>
                    <strong>Additional Encrypted:</strong> {item.additional.encrypted.toString()}
                  </p>
                </>
              )}
            </div>
          ))}
      </div>
    );
  };

  toggleViewMode = () => {
    this.setState(
      prevState => ({
        viewMode: prevState.viewMode === 'survey' ? 'questions' : 'survey',
      }),
      this.fetchResponses
    );
  };

  renderQuestionSummary = (questionId, responses) => {
    const question = this.props.cache.arweaveContent[questionId];
    if (!question) return null;

    const { prompt, type } = question;
    const totalResponses = responses.length;
    const nonEncryptedResponses = responses.filter(r => !r.response.answer.encrypted);

    let summary;
    switch (type) {
      case 'freeform':
        summary = (
          <div>
            <p>Total responses: {totalResponses}</p>
            <p>Non-encrypted responses: {nonEncryptedResponses.length}</p>
            <Button onClick={() => this.showRespondents(questionId)}>View Respondents</Button>
          </div>
        );
        break;
      case 'multichoice':
        const options = question.options || [];
        const optionCounts = options.reduce((acc, option) => {
          acc[option] = nonEncryptedResponses.filter(r => r.response.answer.value === option).length;
          return acc;
        }, {});
        summary = (
          <div>
            {options.map(option => (
              <p key={option}>
                {option}: {((optionCounts[option] / nonEncryptedResponses.length) * 100).toFixed(2)}%
              </p>
            ))}
          </div>
        );
        break;
      case 'binary':
        const agreeCounts = {
          Agree: nonEncryptedResponses.filter(r => r.response.answer.value === 'Agree').length,
          Disagree: nonEncryptedResponses.filter(r => r.response.answer.value === 'Disagree').length,
          Pass: nonEncryptedResponses.filter(r => r.response.answer.value === 'Pass').length,
        };
        summary = (
          <div>
            {Object.entries(agreeCounts).map(([option, count]) => (
              <p key={option}>
                {option}: {((count / nonEncryptedResponses.length) * 100).toFixed(2)}%
              </p>
            ))}
          </div>
        );
        break;
      case 'rating':
        const ratings = nonEncryptedResponses.map(r => parseInt(r.response.answer.value)).filter(r => !isNaN(r));
        const median = ratings.sort((a, b) => a - b)[Math.floor(ratings.length / 2)];
        const mean = ratings.reduce((sum, r) => sum + r, 0) / ratings.length;
        const mode = ratings.sort((a, b) =>
          ratings.filter(v => v === a).length - ratings.filter(v => v === b).length
        ).pop();
        summary = (
          <div>
            <p>Median: {median}</p>
            <p>Mean: {mean.toFixed(2)}</p>
            <p>Mode: {mode}</p>
          </div>
        );
        break;
      default:
        summary = <p>Unknown question type</p>;
    }

    return (
      <Card key={questionId} className={styles.questionSummaryCard}>
        <CardHeader>{prompt}</CardHeader>
        <CardBody>
          <p>Question ID: {questionId}</p>
          {summary}
        </CardBody>
      </Card>
    );
  };

  showRespondents = (questionId) => {
    const responses = this.state.questionResponses[questionId];
    const respondents = responses.map(r => r.responder);

    this.setState({
      modalContent: (
        <div>
          <h3>Respondents for Question ID: {questionId}</h3>
          <ul>
            {respondents.map((address, index) => (
              <li key={index}>
                <a href={`/${questionId}/${address}`} target="_blank" rel="noopener noreferrer">
                  {address}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ),
      showModal: true
    });
  };

  render() {
    const {
      responses, filter, exportType, activeToggles, modalOpen, sbtFilter,
      showAdvancedFilter, alertMessage, loading, viewMode, questionResponses
    } = this.state;

    if (loading) {
      return (
        <div className={styles.loadingContainer}>
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
          <p>Loading results...</p>
        </div>
      );
    }

    const filteredResponses = responses.filter(response =>
      response.responder.toLowerCase().includes(filter.toLowerCase())
    );

    return (
      <Modal isOpen={modalOpen} toggle={this.closeModal} className={styles.resultsModal}>
        <ModalHeader toggle={this.closeModal} className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>Survey Results</h2>
          <Button className={styles.closeButton} onClick={this.closeModal}>
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className={styles.viewModeToggle}>
            <Button onClick={this.toggleViewMode}>
              Switch to {viewMode === 'survey' ? 'Questions' : 'Survey'} View
            </Button>
          </div>

          <div className={styles.filterContainer}>
            <Input
              type="text"
              placeholder="Filter by address"
              value={filter}
              onChange={this.handleFilterChange}
              className={styles.filterInput}
            />
            <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
            <Button onClick={this.toggleAdvancedFilter} className={styles.advancedFilterButton}>
              <FontAwesomeIcon icon={faFilter} /> Advanced Filter
            </Button>
          </div>

          <Collapse isOpen={showAdvancedFilter}>
            <div className={styles.advancedFilterContainer}>
              <Input
                type="text"
                placeholder="Filter by SBT (e.g., AND SBT1 OR SBT2 NOT SBT3)"
                value={sbtFilter}
                onChange={this.handleSBTFilterChange}
                className={styles.sbtFilterInput}
              />
              <Alert color="info" className={styles.sbtFilterInfo}>
                Use AND, OR, NOT operators to create complex SBT filters.
              </Alert>
            </div>
          </Collapse>

          {alertMessage && (
            <Alert color="info" className={styles.alertMessage}>
              {alertMessage}
            </Alert>
          )}

          {viewMode === 'survey' ? (
            <div className={styles.responseList}>
              {filteredResponses.length === 0 ? (
                <p>No results yet.</p>
              ) : (
                filteredResponses.map((response, index) => (
                  <div key={index} className={styles.responseItem}>
                    <div className={styles.responseHeader} onClick={() => this.toggleResponse(index)}>
                      <span className={styles.responderAddress}>
                        <a
                          href={`/u/${response.responder}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={e => e.stopPropagation()}
                          className={styles.responderLink}
                        >
                          {proposalScripts.getShortenedAddress(response.responder)}
                        </a>

                        <a
                          href={`/survey/${this.props.surveyId}/${response.responder}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={e => e.stopPropagation()}
                          className={styles.externalLink}
                        >
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </a>

                        {response.response && response.response.encryptedPortion && (
                          <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />
                        )}
                      </span>
                      <FontAwesomeIcon icon={faCaretDown} className={styles.caretIcon} />
                    </div>
                    <Collapse isOpen={!!activeToggles[index]}>
                      <Card>
                        <CardBody className={styles.responseCard}>
                          {this.renderResponseContent(response)}
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
                ))
              )}
            </div>
          ) : (
            <div className={styles.questionSummaries}>
              {Object.entries(questionResponses).map(([questionId, responses]) =>
                this.renderQuestionSummary(questionId, responses)
              )}
            </div>
          )}

          <div className={styles.exportContainer}>
            <Label for="exportType" className={styles.exportLabel}>
              EXPORT AS:
            </Label>
            <div className={styles.exportOptions}>
              <UncontrolledDropdown direction="up">
                <DropdownToggle caret className={styles.exportDropdown}>
                  {exportType}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => this.handleExportTypeChange('CSV')}>CSV</DropdownItem>
                  <DropdownItem onClick={() => this.handleExportTypeChange('Polis Report')}>Polis Report</DropdownItem>
                  <DropdownItem onClick={() => this.handleExportTypeChange('Talk To The City Report')}>
                    Talk To The City Report
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Button onClick={this.downloadCSV} className={styles.downloadButton}>
                Download
              </Button>
            </div>
          </div>
        </ModalBody>

        <Modal isOpen={this.state.showModal} toggle={() => this.setState({ showModal: false })}>
          <ModalHeader toggle={() => this.setState({ showModal: false })}>Respondents</ModalHeader>
          <ModalBody>
            {this.state.modalContent}
          </ModalBody>
        </Modal>
      </Modal>
    );
  }
}

export default SurveyTool;