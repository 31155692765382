// ------------------------------------------------------------------------------------------------------------------
//
//  this file: GLOBAL VARIABLE(S) and settings USED BY FRONT-END 
//      - TODO: convert to .env, add more details, clean up
//      - TODO: make it a one-switch config, so you only change PROD_MODE to "test" for example

//  contains: VARIABLES / PRESETS FOR DIFFERENT ENVIRONMENTS (dev / test / prod / etc.)
// 
// ------------------------------------------------------------------------------------------------------------------
//
//  Smart-contract deployment environments: Choose a preset / option **for_each**:
//
//     - **BLOCKCHAIN CONFIG**
//          - localhost:8545
//          - Azure / Geth
//          - SKALE SDK Private Box (Christine Set up)
//          - SKALE / ETH Mainnet (TODO)
//
//     - **SERVER CONFIG**
//          - localhost:5000
//          - heroku ()
//
// ------------------------------------------------------------------------------------------------------------------

// ****************************************** GLOBAL VARIABLES ****************************************** //

export const featured_SBTs_LIST = [
    "0xa9651732A878B9Ed3382268b39347B1a2AD1B81e",
    "0x69310be99b4e4D3136C600ed948E89A0f2DcD837",
    "0x2ff32ce5C44d67d756200e2025bd380f1Dc1f66b"
  ];

export const BLOCKED_SURVEY_IDS = [
    "0x1234567890123456789012345678901234567890123456789012345678901234", // Example blocked survey ID
    "0x8b0bab75ce91787079f53d48cf758ea3e8c61c66c54299a2655435cf40a949ac", // Add more as needed
  ]; 

export const HIGHLIGHTED_SURVEY_IDS = [
  '0x1234567890123456789012345678901234567890123456789012345678901234', // Example highlighted survey ID
  '0x8b0bab75ce91787079f53d48cf758ea3e8c61c66c54299a2655435cf40a949ac', // Add more as needed
];

// ****************************************** BOOLEAN OPTIONS ******************************************* //

// export const SEND_TEST_ETH = true; // TODO: Change to encompass L2 if relevant
export const SEND_TEST_ETH = false;

// export const SERVER_ONLINE = true;
export const SERVER_ONLINE = false;

// export const ARWEAVE_ACTIVE = true;
export const ARWEAVE_ACTIVE = true;


// ****************************************** BLOCKCHAIN CONFIG ****************************************** //

// --------------------------------- TYPES OF VARIABLES (TODO: in each preset?)  

// CONTRACT ADDRESSES
// export const PROXY_CONTRACT = 'TODO';
// export const ADMIN_ADDRESS = 'TODO';
// export const MATCHES_ADDRESS = 'TODO'; 
// export const ACCOUNTS_ADDRESS = 'TODO';
// export const LOBBIESANDPROPOSALS_ADDRESS = 'TODO'; 
// export const MW_XP_ADDRESS = TODO'; 

// WEB3 CONSTANTS (TODO)
export const SKALE_GASPRICE = '1';
export const DEFAULT_GASPRICE = '25000000';
// export const DEFAULT_GASPRICE = SKALE_GASPRICE;
// export const TORUS_GASPRICE = SKALE_GASPRICE;
export const TORUS_GASPRICE = DEFAULT_GASPRICE;


// BETA + LINKS (TODO)
// TODO: below name - it's a link to use as RPC-URL for the ETH faucet, not the ETH faucet link
// export const ETH_FAUCET_LINK = "https://meme-skale-rpc.skalelabs.com" 
// export const TYPEFORM_SURVEY_URL = "https://memewars.typeform.com/to/QqbNQUvw"
// export const TYPEFORM_SURVEY_URL = "https://16i2r0g36qu.typeform.com/to/MBWnijdi#nftcode=xxxxx"
// Number of XP a new user is granted upon first m_w smart-contract interaction
// NOTE: this is 100000000000000000000 XP in "wei" format, as XP has 18 decimals
export const USER_STARTING_XP_QUANTITY = "100"


// ******************************************* BASE L2 Testnet (Sepolia) ---------------------------------------

export const MATCHES_ADDRESS = '0x977597796DA9465Da7fc15d796FeF40f3aeB23c3'; 
export const ACCOUNTS_ADDRESS = MATCHES_ADDRESS;
export const LOBBIESANDPROPOSALS_ADDRESS = MATCHES_ADDRESS;
export const MW_XP_ADDRESS = MATCHES_ADDRESS;

export const SURVEYS_ADDRESS = '0x54e6bc23ad9c79a881939f87fa678fb1d24aedc8';
export const SURVEYS_BASE_ADDRESS = '0x54e6bc23ad9c79a881939f87fa678fb1d24aedc8'; // Update as needed
export const SURVEYS_BASE_SEPOLIA_ADDRESS = "0x54e6bc23ad9c79a881939f87fa678fb1d24aedc8"; // Update as needed

export const SBT_FACTORY_ADDRESS = '0x25377B792C053687B468fd98B99C9Cd8b3B5131c';
export const SBT_FACTORY_BASE_ADDRESS = '0x25377B792C053687B468fd98B99C9Cd8b3B5131c'; // Update as needed
export const SBT_FACTORY_BASE_SEPOLIA_ADDRESS = '0x25377B792C053687B468fd98B99C9Cd8b3B5131c'; // Update as needed

export const ACCOUNTS_BASE_ADDRESS = ACCOUNTS_ADDRESS // Update with actual address
export const ACCOUNTS_BASE_SEPOLIA_ADDRESS = ACCOUNTS_ADDRESS // Update with actual address


// ENDPOINTS
export const BASE_HTTP_ENDPOINT = "https://sepolia.base.org"
// export const LOCALHOST_HTTPS_ENDPOINT = "https://127.0.0.1:8545"
// export const BASE_HTTPS_ENDPOINT = "http://127.0.0.1:8545"             // NOTE: not HTTPS!
export const BASE_WS_ENDPOINT = "- ws://sepolia.base.org"
// export const LOCALHOST_WSS_ENDPOINT = "wss://127.0.0.1:8545"
// export const LOCALHOST_WSS_ENDPOINT = "ws://127.0.0.1:8545"                 // NOTE: not HTTPS!
export const ETH_FAUCET_LINK = "http://127.0.0.1:8545";                       // TODO: Add Faucet Link for relevant Testnet



// BLOCKCHAIN DETAILS
export const NETWORK_NAME = "Base Sepolia"
export const DEFAULT_BLOCKCHAIN_ID = 84532; // NOTE: if glitching, turn back into a string "1337"
export const WS_BLOCKCHAIN = BASE_WS_ENDPOINT;
export const WSS_BLOCKCHAIN = BASE_WS_ENDPOINT;
export const HTTPS_BLOCKCHAIN = BASE_HTTP_ENDPOINT;
export const HTTP_BLOCKCHAIN = BASE_HTTP_ENDPOINT;
//
export const FIRST_BLOCKNUMBER_BASE_SEPOLIA = 15719840; 
export const FIRST_BLOCKNUMBER_BASE = 15719840;
export const START_BLOCKNUMBER_FILTER = true;
// TODO: Update ^^^ as needed, this is only for base-sepolia and
// is a response to -32000 error "block range too large" for searching for surveys, survey responses, and SBT creations


// ****************************************** LOCALHOST --------------------------------------

// export const MATCHES_ADDRESS = '0x977597796DA9465Da7fc15d796FeF40f3aeB23c3'; 
// export const ACCOUNTS_ADDRESS = MATCHES_ADDRESS;
// export const LOBBIESANDPROPOSALS_ADDRESS = MATCHES_ADDRESS;
// export const MW_XP_ADDRESS = MATCHES_ADDRESS;
// // export const SURVEYS_ADDRESS = '0x1AdfB38E1a5c4A586C83EACAe660872a6182A4dE';
// export const SURVEYS_ADDRESS = '0x083F25ed4d5859072eFa8AC68EB97fb239Ed20C4';
// export const SBT_FACTORY_ADDRESS = '0x083F25ed4d5859072eFa8AC68EB97fb239Ed20C4';

// // ENDPOINTS
// export const LOCALHOST_HTTP_ENDPOINT = "http://127.0.0.1:8545"
// // export const LOCALHOST_HTTPS_ENDPOINT = "https://127.0.0.1:8545"
// export const LOCALHOST_HTTPS_ENDPOINT = "http://127.0.0.1:8545"             // NOTE: not HTTPS!
// export const LOCALHOST_WS_ENDPOINT = "ws://127.0.0.1:8545"
// // export const LOCALHOST_WSS_ENDPOINT = "wss://127.0.0.1:8545"
// export const LOCALHOST_WSS_ENDPOINT = "ws://127.0.0.1:8545"                 // NOTE: not HTTPS!
// export const ETH_FAUCET_LINK = "http://127.0.0.1:8545";                       // TODO: Add Faucet Link for relevant Testnet



// // BLOCKCHAIN DETAILS
// export const NETWORK_NAME = "mw_localhost"
// export const BLOCKCHAIN_ID = 1337; // NOTE: if glitching, turn back into a string "1337"
// export const WS_BLOCKCHAIN = LOCALHOST_WS_ENDPOINT;
// export const WSS_BLOCKCHAIN = LOCALHOST_WS_ENDPOINT;
// export const HTTPS_BLOCKCHAIN = LOCALHOST_HTTP_ENDPOINT;
// export const HTTP_BLOCKCHAIN = LOCALHOST_HTTP_ENDPOINT;


// ****************************************** AZURE GETH --------------------------------------

// CONTRACT ADDRESSES
// export const MATCHES_ADDRESS = '0xEA13e0beC3Dff6Fb88e13768b25196DECa6E1e75'; 

// ENDPOINTS
// export const GETH_PRIVATE_NETWORK_HTTP = "http://mw-geth-testnet.eastus.azurecontainer.io:8547" 
// export const GETH_PRIVATE_NETWORK_HTTPS = "https://mw-geth-testnet.eastus.azurecontainer.io:80"
// // export const GETH_PRIVATE_NETWORK_WS = "ws://mw-geth-testnet.eastus.azurecontainer.io:8548" 
// export const GETH_PRIVATE_NETWORK_WS = "ws://mw-geth-testnet.eastus.azurecontainer.io:8547" 
// export const GETH_PRIVATE_NETWORK_WSS = "wss://mw-geth-testnet.eastus.azurecontainer.io:80/ws/"
// // export const GETH_PRIVATE_NETWORK_WSS = "wss://mw-geth-testnet.eastus.azurecontainer.io/ws/"

// BLOCKCHAIN DETAILS
// export const NETWORK_NAME = "memewars geth testnet"
// export const BLOCKCHAIN_ID = "4457";
// export const WS_BLOCKCHAIN = GETH_PRIVATE_NETWORK_WS;
// export const WSS_BLOCKCHAIN = GETH_PRIVATE_NETWORK_WSS;
// export const HTTPS_BLOCKCHAIN = GETH_PRIVATE_NETWORK_HTTPS;
// export const HTTP_BLOCKCHAIN = GETH_PRIVATE_NETWORK_HTTP;


// ****************************************** SKALE PRIVATE SDK BOX (DigitalOcean) ------------

// // CONTRACT ADDRESSES
// // export const MATCHES_ADDRESS = '0x6B73E53DE25ceb8F2Fe81C4DB2CAbC6e7f672a96'; 
// // NOTE: ^ is currently deployed at https://www.memewa.rs
// export const MATCHES_ADDRESS = '0xa81945dC4B7DF1e28bdc32673D6C6059EE37Ff13';  
// export const ACCOUNTS_ADDRESS = MATCHES_ADDRESS;
// export const LOBBIESANDPROPOSALS_ADDRESS = MATCHES_ADDRESS;
// export const MW_XP_ADDRESS = MATCHES_ADDRESS;

// // ENDPOINTS (ORIGINAL DIGITAL OCEAN)
// // export const SKALE_BOX_HTTP = "http://128.199.211.235:1234";
// // export const SKALE_BOX_HTTPS = "https://meme-skale-rpc.skalelabs.com";
// // export const SKALE_BOX_WS = "wss://meme-skale-wws.skalelabs.com";
// // export const SKALE_BOX_WSS = "wss://meme-skale-wws.skalelabs.com";

// // ENDPOINTS (NEW BOX CHRISTINE SENT AFTER)
// export const SKALE_BOX_HTTPS = "https://dev-testnet-v1-1.skalelabs.com";
// export const SKALE_BOX_WSS = "wss://dev-testnet-wss-v1-1.skalelabs.com";
// export const ETH_FAUCET_LINK = SKALE_BOX_HTTPS;

// // ENDPOINTS (LOCAL)
// export const LOCAL_HTTP = "http://localhost:8545"

// // BLOCKCHAIN DETAILS
// // export const NETWORK_NAME = "MW_SKALE Testnet"
// export const NETWORK_NAME = "Bob"
// export const BLOCKCHAIN_ID = "54713";
// // export const HTTP_BLOCKCHAIN = SKALE_BOX_HTTP;
// export const HTTPS_BLOCKCHAIN = LOCAL_HTTP;
// // export const HTTPS_BLOCKCHAIN = SKALE_BOX_HTTPS;
// // export const WS_BLOCKCHAIN = SKALE_BOX_WS;
// export const WSS_BLOCKCHAIN = SKALE_BOX_WSS;


// ****************************************** SKALE / ETH MAINNET (TODO) ----------------------






// ****************************************** SERVER PRESETS ****************************************** //


// // //  HEROKU
// export const HEROKU_SERVER = "https://mw-server.herokuapp.com/";
// export const SERVER = HEROKU_SERVER;
// export const SERVER_HTTPS = HEROKU_SERVER;

// LOCALHOST 5000
export const LOCAL_SERVER = "http://localhost:5000";            
export const SERVER = LOCAL_SERVER;
export const LOCAL_SERVER_HTTPS = "http://localhost:5000";     
export const SERVER_HTTPS = LOCAL_SERVER_HTTPS;

// // LOCALHOST 4000
// export const LOCAL_SERVER = "http://localhost:4000";            
// export const SERVER = LOCAL_SERVER;
// export const LOCAL_SERVER_HTTPS = "http://localhost:4000";     
// export const SERVER_HTTPS = LOCAL_SERVER_HTTPS;


// ****************************** APIs / ENDPOINTS / TOKENS CREDENTIALS ******************************* //
// TODO: move these into .env and protect before deloying

// FAUCET FUNDS sent from client in contractScripts.js
export const FAUCET_PRIVKEY = "33712876648b7980adf9b902ef8fd47ee1c216a14bdab7397780541253920eec";

// IMGUR API
export const IMGUR_API = "https://api.imgur.com/3/";
export const IMGUR_CLIENT_ID = "3cb50645cc11da1";

// MIXPANEL 
export const MIXPANEL_CLIENT_ID = "25541f0a65a5b24d3a337cb8157ad74e";

// OpenAI
export const OPENAI_API_KEY = "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";

// Arweave Wallet
export const ARWEAVE_KEY = "";

// Anthropic API
export const ANTHROPIC_API_KEY = "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";
export const USER_ANTHROPIC_KEY = ""; // This should be populated by the user's API key
export const DEFAULT_ANTHROPIC_KEY = ANTHROPIC_API_KEY;

// Alchemy RPC API URLs (Base + Base Sepolia Testnet)

export const ALCHEMY_BASE_URL_HTTP = 'https://base-mainnet.g.alchemy.com/v2/EIQ_V9sdyWgkM314akSV84fNLSnbl6I_';
export const ALCHEMY_BASE_URL_WSS = "wss://base-mainnet.g.alchemy.com/v2/EIQ_V9sdyWgkM314akSV84fNLSnbl6I_";
export const ALCHEMY_BASE_SEPOLIA_URL_HTTP = 'https://base-sepolia.g.alchemy.com/v2/TZk02qYfL45Yac0dfXDGtUwLoav-OPn5';
export const ALCHEMY_BASE_SEPOLIA_URL_WSS = "wss://base-sepolia.g.alchemy.com/v2/TZk02qYfL45Yac0dfXDGtUwLoav-OPn5";






