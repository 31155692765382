import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faCheck, faChevronDown, faChevronUp, faSpinner, faExclamationCircle, faExternalLinkAlt, faCopy } from '@fortawesome/free-solid-svg-icons';
import { ethers } from 'ethers';
import contractScripts from '../Buttons/contractScripts.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { UncontrolledTooltip } from 'reactstrap';
import styles from './CreateSBTGroup.module.scss';

class CreateGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sbtName: '',
      sbtDescription: '',
      sbtImageFile: null,
      sbtImageUrl: '',
      useImageUrl: false,
      sbtCodes: [],
      groupSubmitted: false,
      groupHash: '',
      sbtDistribution: {
        isLimited: false,
        limitedNumber: 0,
        hasAdmin: false,
        adminAddress: props.account || '',
        isRevocable: false,
        isTimeLimited: false,
        mintingEndTime: null,
        distributionOption: 'anyoneCanMint',
        burnAuth: 'AdminOnly',
        burnAdmin: props.account || '',
        network: props.network || 'not connected',
        unlisted: false,
      },
      imageUploaded: false,
      tokenURI: '',
      tokenUriUploaded: false,
      sbtMinted: false,
      sbtAddress: '',
      passwordList: [],
      sbtInviteLinks: [],
      sbtInviteBackupDate: '',
      textToUpload: '',
      csvAddresses: '',
      estimatedMintCost: '0',
      tokenInfoCollapsed: true,
      mintOptionsCollapsed: true,
      distributionOptionsCollapsed: true,
      currentStep: 0,
      mintingFailed: false,
      numInviteLinks: 10,
      network: props.network ? props.network.name : 'not connected',
      copiedLinkIndex: null,
      exportFormat: 'json',
      countdown: 30,  // Added state for countdown
      countdownActive: false,  // Added state for countdown active flag
      sbtSymbol: '', // Added state for SBT symbol
    };
  }

  componentDidMount() {
    console.log("CreateSBTGroup – Initial network:", this.props.network);
    console.log("CreateSBTGroup – Initial network name:", this.props.network ? this.props.network.name : 'not connected');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.network !== prevProps.network) {
      this.setState({
        network: this.props.network ? this.props.network.name : 'not connected',
        sbtDistribution: {
          ...this.state.sbtDistribution,
          network: this.props.network ? this.props.network.name : 'not connected',
        },
      });
    }

    if (this.state.sbtDistribution.isLimited !== prevState.sbtDistribution.isLimited ||
        this.state.sbtDistribution.limitedNumber !== prevState.sbtDistribution.limitedNumber) {
      this.updateNumInviteLinks();
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name.startsWith('sbtDistribution.')) {
      this.setState(
        (prevState) => ({
          sbtDistribution: {
            ...prevState.sbtDistribution,
            [name.split('.')[1]]: value,
          },
        }),
        this.updateGroupHash
      );
    } else {
      this.setState({ [name]: value }, this.updateGroupHash);
    }
  };

  handleImageUpload = (event) => {
    const file = event.target.files[0];
    this.setState({ sbtImageFile: file, sbtImageUrl: '' }, this.updateGroupHash);
  };

  toggleImageUploadMethod = () => {
    // Temporarily disable image upload from URL
    return;
  };

  toggleCollapse = (section) => {
    this.setState((prevState) => ({
      [section]: !prevState[section],
    }));
  };

  updateGroupHash = () => {
    const { sbtName, sbtDescription, sbtImageFile, sbtImageUrl, sbtDistribution } = this.state;
    const groupData = {
      sbtName,
      sbtDescription,
      sbtImageFile,
      sbtImageUrl,
      sbtDistribution,
    };

    const newGroupHash = ethers.utils.id(JSON.stringify(groupData));
    this.setState({ groupHash: newGroupHash });
  };

  handleMintingEndTimeChange = (date) => {
    this.setState(
      (prevState) => ({
        sbtDistribution: {
          ...prevState.sbtDistribution,
          mintingEndTime: date,
        },
      }),
      this.updateGroupHash
    );
  };

  handleBurnAuthChange = (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      sbtDistribution: {
        ...prevState.sbtDistribution,
        burnAuth: value,
      },
    }));
  };

  uploadImageToArweave = async () => {
    this.setState({ currentStep: 1, mintingFailed: false });
    try {
      const { sbtImageFile } = this.state;
      let imageFormat = 'png';

      if (sbtImageFile.type === 'image/jpeg' || sbtImageFile.type === 'image/jpg') {
        imageFormat = 'jpg';
      }

      const imageTxId = await contractScripts.uploadDataToArweave(sbtImageFile, imageFormat);
      console.log("Image uploaded to Arweave with transaction ID:", imageTxId);
      this.setState({ imageUploaded: true, sbtImageUrl: `https://arweave.net/${imageTxId}`, currentStep: 2 }, this.uploadTokenUriToArweave);
    } catch (error) {
      console.error("Failed to upload image to Arweave:", error);
      this.setState({ mintingFailed: true });
    }
  };

  uploadTokenUriToArweave = async () => {
    this.setState({ currentStep: 2, mintingFailed: false });
    try {
      const { sbtName, sbtDescription, sbtImageUrl, sbtDistribution } = this.state;
      const { burnAuth, network, unlisted } = sbtDistribution;
      const tokenUriData = JSON.stringify({
        name: sbtName || "My Test NFT",
        description: sbtDescription || "A test NFT",
        image: sbtImageUrl || "https://arweave.net/WOXBKQEkUPHrOeOhBBckB7OWzrqidnvUnJyW_KUlIOY",
        burnAuth,
        network,
        unlisted,
      });
      const tokenUriTxId = await contractScripts.uploadDataToArweave(tokenUriData, 'json');
      console.log("Token URI uploaded to Arweave with transaction ID:", tokenUriTxId);
      this.setState({ tokenUriUploaded: true, tokenUri: `${tokenUriTxId}`, currentStep: 3 }, this.mintSBT);
    } catch (error) {
      console.error("Failed to upload token URI to Arweave:", error);
      this.setState({ mintingFailed: true });
    }
  };

  mintSBT = async () => {
    console.log('Minting SBT');
    console.log("loginComplete:", this.props.loginComplete);
    if (!this.props.loginComplete) {
      this.props.toggleLoginModal(true);
      return;
    }

    this.setState({ currentStep: 3, mintingFailed: false });

    const { sbtName, sbtDescription, sbtImageUrl, sbtDistribution, passwordList, tokenUri } = this.state;
    const { isLimited, limitedNumber, burnAdmin, isTimeLimited, mintingEndTime, distributionOption, burnAuth } = sbtDistribution;
    const hasPersonalizedUrl = distributionOption === 'hasPasswords';

    const burnAuthEnum = this.getBurnAuthEnum(burnAuth);

    try {
      console.log('Starting mintSBT process');
      console.log('sbtName:', sbtName);
      console.log('sbtDescription:', sbtDescription);
      console.log('sbtImageUrl:', sbtImageUrl);
      console.log('sbtDistribution:', sbtDistribution);
      console.log('passwordList:', passwordList);
      console.log('hasPersonalizedUrl:', hasPersonalizedUrl);
      console.log('burnAuthEnum:', burnAuthEnum);

      console.log('Using provider:', this.props.provider);

      const sbtCount = await contractScripts.countSBTCreated(this.props.provider);
      const sbtSymbol = `CE-SBT-${sbtCount + 1}`;
      this.setState({ sbtSymbol });
      console.log('sbtSymbol:', sbtSymbol);

      // Generate passwords if not already generated
      if (hasPersonalizedUrl && passwordList.length === 0) {
        await this.generatePasswords();
      }

      // Convert passwords to bytes32 format
      const hashedPasswords = passwordList.map(password => {
        const hashedPassword = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(password));
        console.log(`Original password: ${password}, Hashed password: ${hashedPassword}`);
        return hashedPassword;
      });
      console.log('Hashed passwords:', hashedPasswords);

      const mintingEndTimeUnix = isTimeLimited ? Math.floor(mintingEndTime.getTime() / 1000) : 0;

      const receipt = await contractScripts.createSBT(
        this.props.provider,
        sbtName,
        sbtSymbol,
        isLimited ? limitedNumber : 0,
        burnAdmin || this.props.account,
        mintingEndTimeUnix,
        hasPersonalizedUrl,
        burnAuthEnum,
        hashedPasswords,
        'https://arweave.net/' + `${tokenUri}`
      );

      console.log('Transaction receipt:', receipt);

      const sbtAddress = receipt.events[0].address;
      console.log('sbtAddress:', sbtAddress);

      this.setState({ sbtMinted: true, sbtAddress, currentStep: 4 });

      // Generate SBT invite links after successful minting
      if (hasPersonalizedUrl) {
        await this.generateSBTInviteLinks(sbtAddress);
      }

      console.log('SBT minted with address:', sbtAddress);
    } catch (error) {
      console.error("Failed to mint SBT:", error);
      this.setState({ mintingFailed: true });
    }
  };

  getBurnAuthEnum = (burnAuth) => {
    switch (burnAuth) {
      case 'AdminOnly':
        return 0;
      case 'OwnerOnly':
        return 1;
      case 'Both':
        return 2;
      case 'Neither':
        return 3;
      default:
        throw new Error(`Unsupported burnAuth value: ${burnAuth}`);
    }
  };

  generatePasswords = async () => {
    const { numInviteLinks } = this.state;
    const newPasswordList = Array.from({ length: numInviteLinks }, () => this.generateRandomString(8));
    this.setState({ passwordList: newPasswordList });
    console.log('Generated passwords:', newPasswordList);
  };

  generateSBTInviteLinks = async (sbtAddress) => {
    const { passwordList } = this.state;
    const sbtInviteLinks = passwordList.map(password => `${window.location.origin}/sbt/${sbtAddress}/${password}`);
    const sbtInviteBackupDate = new Date().toISOString().slice(0, 10);

    this.setState({ 
      sbtInviteLinks, 
      sbtInviteBackupDate 
    });
  };

  generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  downloadSBTInviteBackup = () => {
    const { sbtInviteLinks, sbtInviteBackupDate } = this.state;
    const backupData = {
      sbtAddress: this.state.sbtAddress,
      inviteLinks: sbtInviteLinks,
    };
    const backupJson = JSON.stringify(backupData, null, 2);
    const backupBlob = new Blob([backupJson], { type: 'application/json' });
    const backupUrl = URL.createObjectURL(backupBlob);
    const backupLink = document.createElement('a');
    backupLink.href = backupUrl;
    backupLink.download = `sbt-invite-backup-${sbtInviteBackupDate}.json`;
    document.body.appendChild(backupLink);
    backupLink.click();
    document.body.removeChild(backupLink);
    URL.revokeObjectURL(backupUrl);
  };

  massSendSBTs = async () => {
    const { csvAddresses } = this.state;
    const addresses = csvAddresses.split(',').map((address) => address.trim());

    try {
      console.log("Stub function for mass sending SBTs to addresses:", addresses);
    } catch (error) {
      console.error("Failed to mass send SBTs:", error);
    }
  };

  handleNumInviteLinksChange = (event) => {
    const numInviteLinks = parseInt(event.target.value);
    this.setState({ numInviteLinks }, this.generatePasswords);
  };

  copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      this.setState({ copiedLinkIndex: index });
      setTimeout(() => this.setState({ copiedLinkIndex: null }), 2000);
    });
  };

  handleExportFormatChange = (event) => {
    this.setState({ exportFormat: event.target.value });
  };

  exportPasswords = () => {
    const { passwordList, sbtInviteLinks, exportFormat, sbtSymbol, sbtName } = this.state;
    const date = new Date().toISOString().slice(0, 10);
    let content;
    let fileName;

    if (exportFormat === 'json') {
      content = JSON.stringify(passwordList.map((password, index) => ({
        index,
        password,
        inviteLink: sbtInviteLinks[index]
      })), null, 2);
      fileName = `${sbtSymbol}_${sbtName}_passwords_${date}.json`;
    } else if (exportFormat === 'csv') {
      content = 'index,password,inviteLink\n' + 
        passwordList.map((password, index) => 
          `${index},${password},${sbtInviteLinks[index]}`
        ).join('\n');
      fileName = `${sbtSymbol}_${sbtName}_passwords_${date}.csv`;
    }

    const blob = new Blob([content], { type: exportFormat === 'json' ? 'application/json' : 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  startClaim = async () => {
    if (!this.state.countdownActive) {
      this.setState({ countdownActive: true, countdown: 30 });
      this.countdownTimer = setInterval(() => {
        this.setState(prevState => ({
          countdown: prevState.countdown - 1
        }), () => {
          if (this.state.countdown === 0) {
            clearInterval(this.countdownTimer);
            this.setState({ countdownActive: false });
          }
        });
      }, 1000);
    }
  };

  claimWithPassword = async (password) => {
    try {
      const hashedPassword = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(password));
      console.log(`Claiming with password: ${password}, Hashed: ${hashedPassword}`);
      await contractScripts.claimWithPassword(this.props.provider, this.state.sbtAddress, password);
      console.log("SBT claimed successfully");
    } catch (error) {
      console.error("Failed to claim SBT:", error);
    }
  };

  updateNumInviteLinks = () => {
    const { isLimited, limitedNumber } = this.state.sbtDistribution;
    if (isLimited && this.state.sbtDistribution.distributionOption === 'hasPasswords') {
      this.setState({ numInviteLinks: limitedNumber });
    }
  };

  render() {
    const {
      sbtName,
      sbtDescription,
      sbtImageFile,
      sbtImageUrl,
      useImageUrl,
      sbtDistribution,
      imageUploaded,
      tokenUri,
      tokenUriUploaded,
      sbtMinted,
      sbtAddress,
      csvAddresses,
      estimatedMintCost,
      tokenInfoCollapsed,
      mintOptionsCollapsed,
      distributionOptionsCollapsed,
      currentStep,
      mintingFailed,
      sbtInviteLinks,
      numInviteLinks,
      network,
      copiedLinkIndex,
      exportFormat,
      countdown,  // Added countdown state
      countdownActive,  // Added countdown active state
    } = this.state;

    const isMintSBTDisabled = !(
      sbtDistribution.distributionOption === 'hasPasswords' ||
      sbtDistribution.distributionOption === 'anyoneCanMint' ||
      (sbtDistribution.distributionOption === 'massSend' && csvAddresses.trim() !== '')
    );

    const calendarStyles = {
      color: 'black',
    };

    return (
      <div id={styles.createGroupExpanded}>
        <div className={styles.headerContainer}>
          <h1 className={styles.createGroupTitle}>Create SBT / Group</h1>
          <FontAwesomeIcon icon={faQuestionCircle} className={styles.tooltip} id="learnMoreTooltip" />
          <UncontrolledTooltip placement="right" target="learnMoreTooltip" delay={{ show: 0, hide: 5000 }} className={styles.tooltip}>
            Learn more about SBTs (Soulbound Tokens = Non-transferrable NFTs). <br />
            <a href="https://github.com/Ensoul-Labs/awesome-soulbound-tokens" target="_blank" rel="noopener noreferrer">
              More Info
            </a>
          </UncontrolledTooltip>
        </div>
        <div className={styles.collapsibleSection}>
          <h3 onClick={() => this.toggleCollapse('tokenInfoCollapsed')}>
            SBT Token Info {tokenInfoCollapsed ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
          </h3>
          {!tokenInfoCollapsed && (
            <div className={styles.inputColumn}>
              <input
                type="text"
                name="sbtName"
                value={sbtName}
                onChange={this.handleInputChange}
                placeholder="SBT Name"
                id={styles.sbtName}
              />
              <input
                type="text"
                name="sbtDescription"
                value={sbtDescription}
                onChange={this.handleInputChange}
                placeholder="Event / Group Description"
                id={styles.sbtDescription}
              />
              <div className={styles.imageUploadContainer}>
                <label className={styles.imageUploadLabel}>SBT Image</label>
                <div className={styles.imageUploadOptions}>
                  <label className={styles.imageUploadOption}>
                    <input
                      type="radio"
                      checked={!useImageUrl}
                      onChange={this.toggleImageUploadMethod}
                    />
                    Upload Image
                  </label>
                  <label className={styles.imageUploadOption} style={{ pointerEvents: 'none', opacity: 0.5 }}>
                    <input
                      type="radio"
                      checked={useImageUrl}
                      onChange={this.toggleImageUploadMethod}
                    />
                    Use Image URL
                  </label>
                </div>
                {useImageUrl ? (
                  <input
                    type="text"
                    name="sbtImageUrl"
                    value={sbtImageUrl}
                    onChange={this.handleInputChange}
                    placeholder="Enter image URL"
                  />
                ) : (
                  <div className={styles.fileUploadContainer}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={this.handleImageUpload}
                      style={{ display: 'none' }}
                      ref={(fileInput) => (this.fileInput = fileInput)}
                    />
                    <button
                      type="button"
                      onClick={() => this.fileInput.click()}
                      className={styles.fileUploadButton}
                    >
                      Choose Image
                    </button>
                    {sbtImageFile && (
                      <span className={styles.selectedFile}>
                        {sbtImageFile.name}
                      </span>
                    )}
                  </div>
                )}
              </div>
              {(sbtImageFile || sbtImageUrl) && (
                <div className={styles.imagePreview}>
                  <img
                    src={sbtImageFile ? URL.createObjectURL(sbtImageFile) : sbtImageUrl}
                    alt="SBT"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.collapsibleSection}>
          <h3 onClick={() => this.toggleCollapse('mintOptionsCollapsed')}>
            SBT Mint Options {mintOptionsCollapsed ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
          </h3>
          {!mintOptionsCollapsed && (
            <div className={styles.sbtTokenOptions}>
              <label>
                <input
                  type="checkbox"
                  name="sbtDistribution.isLimited"
                  checked={sbtDistribution.isLimited}
                  onChange={this.handleInputChange}
                />
                <span>Limited Number of Tokens</span>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="limitedNumberTooltip"
                />
                <UncontrolledTooltip placement="right" target="limitedNumberTooltip">
                  Specify the maximum number of SBTs that can be minted.
                </UncontrolledTooltip>
              </label>
              {sbtDistribution.isLimited && (
                <input
                  type="number"
                  name="sbtDistribution.limitedNumber"
                  value={sbtDistribution.limitedNumber}
                  onChange={this.handleInputChange}
                  placeholder="Enter limited number"
                />
              )}
              <label>
                <input
                  type="checkbox"
                  name="sbtDistribution.isTimeLimited"
                  checked={sbtDistribution.isTimeLimited}
                  onChange={this.handleInputChange}
                />
                <span>Time-Limited Minting</span>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="timeLimitedTooltip"
                />
                <UncontrolledTooltip placement="right" target="timeLimitedTooltip">
                  Set an end time for the minting period. After this time, no more SBTs can be minted.
                </UncontrolledTooltip>
              </label>
              {sbtDistribution.isTimeLimited && (
                <div className={styles.timeLimitedOptions}>
                  <DatePicker
                    selected={sbtDistribution.mintingEndTime}
                    onChange={this.handleMintingEndTimeChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    id={styles.datePicker}
                    calendarClassName={styles.blackText}
                    style={calendarStyles}
                  />
                  <span className={styles.unixTimestamp}>
                    Unix Timestamp: {sbtDistribution.mintingEndTime ? Math.floor(sbtDistribution.mintingEndTime.getTime() / 1000) : '-'}
                  </span>
                </div>
              )}
              <label>
                <span>Burn Authorization</span>
                <select name="sbtDistribution.burnAuth" value={sbtDistribution.burnAuth} onChange={this.handleBurnAuthChange}>
                  <option value="AdminOnly">Admin Only</option>
                  <option value="OwnerOnly">Owner Only</option>
                  <option value="Both">Both</option>
                  <option value="Neither">Neither</option>
                </select>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="burnAuthTooltip"
                />
                <UncontrolledTooltip placement="right" target="burnAuthTooltip">
                  Specify who can burn the token: Admin Only, Owner Only, Both, or Neither.
                </UncontrolledTooltip>
              </label>
              <label>
                <span>Burn Admin Address </span>
                <input
                  type="text"
                  name="sbtDistribution.burnAdmin"
                  value={sbtDistribution.burnAdmin}
                  onChange={this.handleInputChange}
                  placeholder="default = deployer address"
                />
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="burnAdminTooltip"
                />
                <UncontrolledTooltip placement="right" target="burnAdminTooltip">
                  Enter the address that can burn the token. Defaults to the connected address.
                </UncontrolledTooltip>
              </label>
              <label>
                <span>Network: </span>
                <button className={styles.networkButton} onClick={() => this.props.toggleLoginModal(true)}>
                  {network}
                </button>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="networkTooltip"
                />
                <UncontrolledTooltip placement="right" target="networkTooltip">
                  Select the network for minting.
                </UncontrolledTooltip>
              </label>
            </div>
          )}
        </div>
        <div className={styles.collapsibleSection}>
          <h3 onClick={() => this.toggleCollapse('distributionOptionsCollapsed')}>
            SBT Distribution Options {distributionOptionsCollapsed ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
          </h3>
          {!distributionOptionsCollapsed && (
            <div className={styles.distributionOptions}>
              <label>
                <input
                  type="radio"
                  name="sbtDistribution.distributionOption"
                  value="hasPasswords"
                  checked={sbtDistribution.distributionOption === 'hasPasswords'}
                  onChange={this.handleInputChange}
                />
                One-use URLs
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="oneUseTooltip"
                />
                <UncontrolledTooltip placement="right" target="oneUseTooltip">
                  Generate one-use URLs / passwords for each SBT.
                </UncontrolledTooltip>
              </label>
              <label>
                <input
                  type="radio"
                  name="sbtDistribution.distributionOption"
                  value="anyoneCanMint"
                  checked={sbtDistribution.distributionOption === 'anyoneCanMint'}
                  onChange={this.handleInputChange}
                />
                URL where anyone can mint
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="anyoneCanMintTooltip"
                />
                <UncontrolledTooltip placement="right" target="anyoneCanMintTooltip">
                  Generate a URL where anyone can mint the SBT.
                </UncontrolledTooltip>
              </label>
              {sbtDistribution.distributionOption === 'hasPasswords' && (
                <div className={styles.inviteLinksOptions}>
                  <label>
                    Number of Invite Links:
                    <input
                      type="number"
                      value={numInviteLinks}
                      onChange={this.handleNumInviteLinksChange}
                      min="1"
                    />
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className={styles.tooltip}
                      id="numInviteLinksTooltip"
                    />
                    <UncontrolledTooltip placement="right" target="numInviteLinksTooltip">
                      {sbtDistribution.isLimited 
                        ? `You can create up to ${sbtDistribution.limitedNumber} invite links. An admin can create more later if needed.`
                        : "An admin can create more invite links later if needed."}
                    </UncontrolledTooltip>
                  </label>
                </div>
              )}
              <label>
                <input
                  type="checkbox"
                  name="sbtDistribution.unlisted"
                  checked={sbtDistribution.unlisted}
                  onChange={this.handleInputChange}
                />
                <span>Unlisted SBT</span>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="unlistedTooltip"
                />
                <UncontrolledTooltip placement="right" target="unlistedTooltip">
                  If checked, the SBT will not appear in the public list but will still be discoverable via the Arweave transaction if not encrypted.
                </UncontrolledTooltip>
              </label>
            </div>
          )}
        </div>
        <div className={styles.mintingSteps}>
          <button onClick={this.uploadImageToArweave} disabled={currentStep > 0 || !this.props.loginComplete}>
            {currentStep === 0 && 'Mint SBT'}
            {currentStep > 0 && (
              <>
                {currentStep === 1 && 'Uploading Image...'}
                {currentStep === 2 && 'Uploading URI...'}
                {currentStep === 3 && 'Minting SBT...'}
                {mintingFailed && currentStep > 0 && <FontAwesomeIcon icon={faExclamationCircle} style={{ marginLeft: '10px', color: 'red' }} />}
              </>
            )}
          </button>
        </div>
        <div className={styles.progressIndicator}>
          <div className={currentStep >= 1 ? styles.stepCompleted : styles.step}>
            <FontAwesomeIcon icon={currentStep === 1 ? faSpinner : currentStep > 1 ? faCheck : faExclamationCircle} spin={currentStep === 1} />
            <span>Upload Image</span>
          </div>
          <div className={currentStep >= 2 ? styles.stepCompleted : styles.step}>
            <FontAwesomeIcon icon={currentStep === 2 ? faSpinner : currentStep > 2 ? faCheck : faExclamationCircle} spin={currentStep === 2} />
            <span>Upload URI</span>
          </div>
          <div className={currentStep >= 3 ? styles.stepCompleted : styles.step}>
            <FontAwesomeIcon icon={currentStep === 3 ? faSpinner : currentStep > 3 ? faCheck : faExclamationCircle} spin={currentStep === 3} />
            <span>Mint SBT</span>
          </div>
        </div>
        <div className={styles.sbtContractAddress}>
          <span>SBT Contract Address: </span>
          {sbtAddress ? (
            <a
              href={`/sbt/${sbtAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} /> SBT Page ({sbtAddress})
            </a>
          ) : (
            <span>-</span>
          )}
        </div>
        {sbtInviteLinks.length > 0 && sbtMinted && (
          <div className={styles.sbtInviteLinks}>
            <h3>SBT Invite Links:</h3>
            <ul>
              {sbtInviteLinks.map((link, index) => (
                <li key={index} className={styles.inviteLinkItem}>
                  <span className={styles.inviteLink}>{link}</span>
                  <button onClick={() => this.copyToClipboard(link, index)} className={styles.copyButton}>
                    <FontAwesomeIcon icon={copiedLinkIndex === index ? faCheck : faCopy} />
                  </button>
                </li>
              ))}
            </ul>
            <div className={styles.exportOptions}>
              <select value={exportFormat} onChange={this.handleExportFormatChange} className={styles.exportFormatSelect}>
                <option value="json">JSON</option>
                <option value="csv">CSV</option>
              </select>
              <button onClick={this.exportPasswords} className={styles.exportButton}>Export Passwords</button>
            </div>
          </div>
        )}
        {this.state.countdownActive && (  // Added countdown display
          <div className={styles.countdownContainer}>
            {/* <svg className={styles.countdownCircle} viewBox="0 0 36 36">
              <path
                d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke="#444"
                strokeWidth="1"
              />
              <path
                d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke="#4CAF50"
                strokeWidth="1"
                strokeDasharray={`${(this.state.countdown / 30) * 100}, 100`}
              />
              <text x="18" y="20.35" className={styles.countdownText}>
                {this.state.countdown}
              </text>
            </svg> */}
          </div>
        )}
      </div>
    );
  }
}

export default CreateGroup;
