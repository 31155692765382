import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faVoteYea, faScroll, faUsersCog, faBuilding, faArrowRight, faArrowLeft, faChevronDown, faChevronUp, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import styles from './CommunityTab.module.scss';
import historicalFigures from '../../variables/historical_figure_users.json';
import logoRxC from "../../assets/img/rxcLogo.png";
import logoPolis from "../../assets/img/polisLogo.png";
import { Modal, ModalHeader, ModalBody, Collapse, UncontrolledTooltip } from 'reactstrap';

const CommunityTab = () => {
  const [displaySection, setDisplaySection] = useState('groups');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });
  const [showMoreLeaderboard, setShowMoreLeaderboard] = useState(false);
  const [onlyHumanUsers, setOnlyHumanUsers] = useState(false);

  const stats = [
    { icon: faUsers, count: 15, label: 'Users' },
    { icon: faVoteYea, count: 250, label: 'Survey Responses' },
    { icon: faScroll, count: 1, label: 'Surveys Created' },
    { icon: faUsersCog, count: 10, label: 'SBTs Created' },
    // { icon: faBuilding, count: 15, label: 'SBTs Collected' },
  ];

  const groups = [
    { name: 'RadicalxChange', logo: logoRxC, description: 'RadicalxChange is a global movement dedicated to reimagining the building blocks of democracy and markets in order to uphold fairness, plurality, and meaningful participation in a rapidly changing world.' },
    { name: 'Pol.is', logo: logoPolis, description: 'Pol.is is a pioneering software platform that facilitates large-scale conversations and helps identify areas of consensus and disagreement on complicated issues.' },
  ];

  const nonSimulatedUser = {
    name: "0x0152...886",
    username: "0x01520025F04b40b19e426A97b51D31fa58C0C886",
    avatar: "https://robohash.org/0x01520025F04b40b19e426A97b51D31fa58C0C886",
    Karma: 50,
  };

  const toggleDisplaySection = () => {
    setDisplaySection(prevSection => prevSection === 'groups' ? 'individuals' : 'groups');
  };

  const handleUserClick = (user) => {
    if (user.username.startsWith('0x')) {
      window.open(`/u/${user.username}`, '_blank');
    } else {
      window.open(`/su/${user.username}`, '_blank');
    }
  };

  const handleStatClick = (stat) => {
    setModalContent({
      title: `${stat.label} Details`,
      content: `Detailed information about ${stat.label.toLowerCase()} will be displayed here.`
    });
    setShowModal(true);
  };

  const handleGroupClick = (group) => {
    setModalContent({
      title: group.name,
      content: (
        <div>
          <img src={group.logo} alt={`${group.name} logo`} className={styles.groupLogo} />
          <p>{group.description}</p>
        </div>
      )
    });
    setShowModal(true);
  };

  const renderLeaderboard = () => {
    let leaderboardData = onlyHumanUsers ? [nonSimulatedUser] : [...historicalFigures, nonSimulatedUser];
    leaderboardData.sort((a, b) => b.Karma - a.Karma);
    
    const topThree = leaderboardData.slice(0, 4);
    const remainingEntries = leaderboardData.slice(4);

    if (leaderboardData.length === 0) {
      return <div className={styles.noUsers}>None yet!</div>;
    }

    return (
      <>
        {topThree.map((user, index) => (
          <div key={index} className={styles.leaderboardItem} onClick={() => handleUserClick(user)}>
            <img src={user.avatar} alt={user.name} className={styles.avatar} />
            <span className={styles.name}>
              {user.name}
              {!user.username.startsWith('0x') && (
                <>
                  <span className={styles.simBadge} id={`sim-tooltip-${index}`}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </span>
                  <UncontrolledTooltip placement="right" target={`sim-tooltip-${index}`}>
                    This is a simulated user.
                  </UncontrolledTooltip>
                </>
              )}
            </span>
            <span className={styles.karma}>{user.Karma}</span>
          </div>
        ))}
        <Collapse isOpen={showMoreLeaderboard}>
          {remainingEntries.map((user, index) => (
            <div key={index + 3} className={styles.leaderboardItem} onClick={() => handleUserClick(user)}>
              <img src={user.avatar} alt={user.name} className={styles.avatar} />
              <span className={styles.name}>
                {user.name}
                {!user.username.startsWith('0x') && (
                  <>
                    <span className={styles.simBadge} id={`sim-tooltip-${index + 3}`}>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </span>
                    <UncontrolledTooltip placement="right" target={`sim-tooltip-${index + 3}`}>
                      This is a simulated user.
                    </UncontrolledTooltip>
                  </>
                )}
              </span>
              <span className={styles.karma}>{user.Karma}</span>
            </div>
          ))}
        </Collapse>
        {leaderboardData.length > 3 && (
          <button onClick={() => setShowMoreLeaderboard(!showMoreLeaderboard)} className={styles.showMoreButton}>
            {showMoreLeaderboard ? (
              <>
                Show Less <FontAwesomeIcon icon={faChevronUp} />
              </>
            ) : (
              <>
                Show More <FontAwesomeIcon icon={faChevronDown} />
              </>
            )}
          </button>
        )}
      </>
    );
  };

  return (
    <div className={styles.communityTab}>
      <div className={styles.leaderboardSection}>
        <h2 className={styles.sectionTitle}>Leaderboard</h2>
        <div className={styles.leaderboardHeader}>
          <label className={styles.toggleLabel}>
            <input
              type="checkbox"
              checked={onlyHumanUsers}
              onChange={() => setOnlyHumanUsers(!onlyHumanUsers)}
              className={styles.toggleCheckbox}
            />
            {/* Only see human users */}
            Hide Simulated Users
          </label>
        </div>
        <div className={styles.content}>
          <div className={styles.leaderboard}>
            <div className={styles.leaderboardHeader}>
              <span>Name</span>
              <span>Karma</span>
            </div>
            {renderLeaderboard()}
          </div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.statsSection}>
          <h2 className={styles.sectionTitle}>Statistics</h2>
          <div className={styles.statsGrid}>
            {stats.map((stat, index) => (
              <div key={index} className={styles.statItem} onClick={() => handleStatClick(stat)}>
                <FontAwesomeIcon icon={stat.icon} size="2x" className={styles.statIcon} />
                <span className={styles.statCount}>{stat.count}</span>
                <span className={styles.statLabel}>{stat.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.recognitionSection}>
          <h2 className={styles.sectionTitle}>Recognition</h2>
          <div className={styles.content}>
            {displaySection === 'groups' ? (
              <>
                <div className={styles.groupsGrid}>
                  {groups.map((group, index) => (
                    <div key={index} className={styles.groupItem} onClick={() => handleGroupClick(group)}>
                      <img src={group.logo} alt={`${group.name} logo`} className={styles.groupLogo} />
                      <span>{group.name}</span>
                    </div>
                  ))}
                </div>
                {/* <button onClick={toggleDisplaySection} className={styles.switchButton}>
                  Individuals <FontAwesomeIcon icon={faArrowRight} />
                </button> */}
              </>
            ) : (
              <>
                <div className={styles.noIndividuals}>
                  No recognized individuals yet.
                </div>
                <button onClick={toggleDisplaySection} className={styles.switchButton}>
                  <FontAwesomeIcon icon={faArrowLeft} /> Groups
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={showModal} toggle={() => setShowModal(false)} className={styles.modal}>
        <ModalHeader toggle={() => setShowModal(false)} className={styles.modalHeader}>{modalContent.title}</ModalHeader>
        <ModalBody className={styles.modalBody}>
          {modalContent.content}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CommunityTab;