import React from 'react';
import Web3 from 'web3';

// reactstrap components
import { NavLink } from "reactstrap";

// Smart Contract Interactions
// MW_XP.sol
import { MW_XP_ADDRESS, ACCOUNTS_ADDRESS, LOBBIESANDPROPOSALS_ADDRESS } from '../../variables/CONTRACT_ADDRESSES.js';
import MW_XP from '../../contractsABI/MW_XP_ABI.json';
import ACCOUNTS from '../../contractsABI/ACCOUNTS_ABI.json';
import LOBBIESANDPROPOSALS from '../../contractsABI/LOBBIESANDPROPOSALS_ABI.json';
import MATCHES from '../../contractsABI/MATCHES_ABI.json';

var BN = Web3.utils.BN;

const proposalScripts = {


  getProposalCardColor: function(format) {
    switch (format) {
      case 'TEXT':
        return 'red';
      case 'TIKTOK':
        return 'black';
      case 'TWEET':
        return 'blue';
      case 'YOUTUBE':
        return 'red';
      case 'IMAGE':
        return 'orange';
        // return 'red';
      case 'GIF':
        return 'green';
    }
  },

  getProposalBadgeColor: function(format) {
    switch (format) {
      case 'TIKTOK':
        return 'tiktok';      // TODO: give tiktok its blue/red glow
      case 'TWEET':
        return 'twitter';
      case 'YOUTUBE':
        return 'youtube';
      case 'IMAGE':
        // return 'image';     // TODO: custom color / orange?
        // return 'twitter';
        return 'orange';
      case 'GIF':
        return 'gif';
    }
  },

  getProposalCardIcon: function(format) {
    switch (format) {
      case 'TIKTOK':
        return <div style={{display: "inline"}}><i className="fas fa-music"></i></div>; // TODO: get real TikTok icon
      case 'TWEET':
        return <div style={{display: "inline"}}><i className="fab fa-twitter"></i></div>;
      case 'YOUTUBE':
        return <div style={{display: "inline"}}><i className="fab fa-youtube"></i></div>;
      case 'IMAGE':
        return <div style={{display: "inline"}}><i className="fas fa-image"></i></div>;
      case 'GIF':
        return <div style={{display: "inline"}}><i className="fas fa-film"></i></div>;
      case 'TEXT':
        return <div style={{display: "inline"}}><i className="fas fa-font"></i></div>;
      // case 'SOUNDCLOUD':
      //   return 
    }
  },

  getProposalVoteTypeIcon: function(voteType) {
    switch (voteType) {
      case 'QUADRATIC':
        return <div style={{display: "inline"}}><i className="fas fa-chart-line"></i></div>;
      //Ranked-Choice Voting
      case 'RCV':
        return <div style={{display: "inline"}}><i className="fas fa-sort-amount-up"></i></div>;
      case '1P1V':
        return <div style={{display: "inline"}}><i className="fas fa-check"></i></div>;
    }
  },

  getProposalClassname: function(format) {
    switch (format) {
      case 'TEXT':
        return "proposal-badge-image";
      case 'TIKTOK':
        return "proposal-badge-tiktok"; // TODO: get real TikTok icon
      case 'TWEET':
        return "proposal-badge-twitter";
      case 'YOUTUBE':
        return "proposal-badge-youtube";
      case 'IMAGE':
        return "proposal-badge-image";
      case 'GIF':
        return "proposal-badge-gif";
    }
  },

getShortenedAddress: function(address, clickable, customLink) {

    const addressClickable = clickable != null ? clickable : true;

    var displayString = "";

    displayString += address.slice(0, 5);
    displayString += "...";
    displayString += address.slice(38, 42);

    if (addressClickable) {
      const link = customLink ? customLink : '/u/' + address.toString();
      const displayStringURL = 
      <>
        <NavLink
          href={link}
          target="_blank"
          style={{padding: "0px"}}
        >
          {displayString}
        </NavLink>
      </>

      return displayStringURL;
    }

    return displayString;
},

getShortenedSurveyID: function(address, clickable, customLink) {

  const addressClickable = clickable != null ? clickable : true;

  var displayString = "";

  displayString += address.slice(0, 5);
  displayString += "...";
  displayString += address.slice(61, 66);

  if (addressClickable) {
    const link = customLink ? customLink : '/u/' + address.toString();
    const displayStringURL = 
    <>
      <NavLink
        href={link}
        target="_blank"
        style={{padding: "0px"}}
      >
        {displayString}
      </NavLink>
    </>

    return displayStringURL;
  }

  return displayString;
}
};



export default proposalScripts;
