import React, { useState, useEffect } from 'react';
import {
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faCircle, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { useWhisper } from '@chengsokdara/use-whisper';

// CSS
import styles from './SurveyTool.module.scss';

// OpenAI API
import { OPENAI_API_KEY } from '../../variables/CONTRACT_ADDRESSES.js';
const apiKey = OPENAI_API_KEY;

const AudioInput = ({ placeholder, updateFunction, toggleEncryption, value, encrypted }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [encryptBoxChecked, setEncryptBoxChecked] = useState(encrypted);
  const [liveTranscript, setLiveTranscript] = useState("");  // Live updates as you speak
  const [userText, setUserText] = useState("");              // Editable by the user
  
  const { transcript, startRecording, stopRecording } = useWhisper({
      apiKey: apiKey,
      removeSilence: false,
      streaming: true,
      timeSlice: 1_000, // 1 second
  });

  useEffect(() => {
      setLiveTranscript(transcript.text);   // Update the live transcript as the user speaks
  }, [transcript.text]);

  useEffect(() => {
    // Initialize userText with the value prop if it's not a placeholder
    if (value && value !== 'response (optional)' && value !== 'related thoughts or URLs (optional)') {
      setUserText(value);
    }
  }, [value]);

  useEffect(() => {
    // Update encryptBoxChecked when the encrypted prop changes
    setEncryptBoxChecked(encrypted);
  }, [encrypted]);

  const handleRecordClick = () => {
      if (isRecording) {
          stopRecording();
        // When recording stops, append the live transcript to the user-editable text
          const updatedText = `${userText} ${liveTranscript}`;
          setUserText(updatedText);
          updateFunction(updatedText); 
      } else {
          startRecording();
      }
      setIsRecording(prevState => !prevState);
  };

  const handleInputChange = (e) => {
      if (!isRecording) {
          setUserText(e.target.value);
          updateFunction(e.target.value);
      }
  };

  const handleEncryptCheckbox = () => {
      const newEncryptedState = !encryptBoxChecked;
      setEncryptBoxChecked(newEncryptedState);
      toggleEncryption(newEncryptedState);
  };

  const getPlaceholder = () => {
    if (userText || (value && value !== 'response (optional)' && value !== 'related thoughts or URLs (optional)')) {
      return '';
    }
    return placeholder;
  };

    return (
        <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
            <InputGroupAddon addonType="append" id={styles.encryptAdditionalThoughts}>
                <div id={styles.encryptOptionButton} onClick={handleEncryptCheckbox}>
                    <FontAwesomeIcon id={styles.encryptIcon} icon={encryptBoxChecked ? faLock : faUnlock} />
                    <InputGroupText id={styles.inputGroupTextBox}>
                        <Input
                            addon
                            type="checkbox"
                            aria-label="encrypt"
                            checked={encryptBoxChecked}
                            onChange={handleEncryptCheckbox}
                            id={styles.encryptCheckbox}
                        />
                        Encrypt
                    </InputGroupText>
                </div>
            </InputGroupAddon>
            <Input
                type="textarea"
                placeholder={getPlaceholder()}
                value={isRecording ? liveTranscript : userText}
                style={{ overflow: 'vertical', flex: '1', fontSize: '1.25em', marginBottom: '10px' }}
                onChange={handleInputChange}
                readOnly={isRecording}
            />
            <button
                onClick={handleRecordClick}
                style={{
                    background: 'transparent',
                    border: 'none',
                    padding: '10px',
                    cursor: 'pointer',
                    fontSize: '30px',
                    color: isRecording ? 'red' : 'grey',
                    borderRadius: '10%',
                    marginBottom: '10px',
                    borderBottomRightRadius: '0px',
                    borderTopRightRadius: '0px',
                    opacity: isRecording ? '1' : '0.5',
                }}
            >
                {isRecording ? (
                    <FontAwesomeIcon icon={faCircle} />
                ) : (
                    <FontAwesomeIcon icon={faMicrophone} />
                )}
            </button>
        </div>
    );
}

export default AudioInput;